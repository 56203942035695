import {
  Grain,
  VpnKey,
  AccountBalanceWallet,
  GamesOutlined,
  AccountBalanceOutlined,
} from "@material-ui/icons";

import Login from "views/auth/Login.js";
import Wallets from "views/admin/wallets";
import Users from "views/admin/Users";
import Games from "views/admin/Games";
import GamesDetails from "views/admin/Games/games";
import Ledger_Wallets from "views/admin/ledger/index";
import Quiz_Ques from "views/admin/QuizQues/index";
import {
  BarChart,
  PeopleAlt,
  QuestionAnswer,
} from "../node_modules/@material-ui/icons/index";
import Topics from "views/admin/Quiz Topic/index";
import { BookOpen, Gift } from "../node_modules/react-feather/dist/index";
import Rewards from "views/admin/rewards/index";
import Campaigns from "views/admin/Campaigns/index";
import CampaignDetails from "views/admin/Campaigns/campaigndetails";
import Analytics from "views/admin/Analytics/index";

var adminRoutes = [
  {
    path: "/dashboard",
    name: "Users",
    icon: Grain,
    iconColor: "Primary",
    component: Users,
    layout: "/app",
    visibility: true,
  },

  {
    path: "/wallets",
    name: "Wallets",
    icon: AccountBalanceWallet,
    iconColor: "Primary",
    component: Wallets,
    layout: "/app",
    visibility: true,
  },
  {
    path: "/games",
    name: "Games",
    icon: GamesOutlined,
    iconColor: "Primary",
    component: Games,
    layout: "/app",
    visibility: true,
  },
  {
    path: "/quiz-topics",
    name: "Quiz Topics",
    icon: BookOpen,
    iconColor: "Primary",
    component: Topics,
    layout: "/app",
    visibility: true,
  },
  {
    path: "/quiz-questions",
    name: "Quiz Questions",
    icon: QuestionAnswer,
    iconColor: "Primary",
    component: Quiz_Ques,
    layout: "/app",
    visibility: true,
  },
  {
    path: "/Rewards",
    name: "Rewards",
    icon: Gift,
    iconColor: "Primary",
    component: Rewards,
    layout: "/app",
    visibility: true,
  },
  {
    path: "/campaigns",
    name: "Campaigns",
    icon: PeopleAlt,
    iconColor: "Primary",
    component: Campaigns,
    layout: "/app",
    visibility: true,
  },
  {
    path: "/campaign/:_id",
    name: "Campaigns",
    icon: Gift,
    iconColor: "Primary",
    component: CampaignDetails,
    layout: "/app",
    visibility: false,
  },
  {
    path: "/analytics",
    name: "Analytics",
    icon: BarChart,
    iconColor: "Primary",
    component: Analytics,
    layout: "/app",
    visibility: true,
  },
  {
    path: "/ledger-wallets",
    name: "Ledger Wallets",
    icon: AccountBalanceOutlined,
    iconColor: "Primary",
    component: Ledger_Wallets,
    layout: "/app",
    visibility: true,
  },

  {
    path: "/game/:_id",
    name: "GamesDetails",
    icon: GamesOutlined,
    iconColor: "Primary",
    component: GamesDetails,
    layout: "/app",
    visibility: false,
  },
  {
    divider: true,
    layout: "/app",
    visibility: true,
  },
];

var authRoutes = [
  {
    path: "/login",
    name: "Login",
    icon: VpnKey,
    iconColor: "Info",
    component: Login,
    layout: "/auth",
  },
];
export { adminRoutes, authRoutes };
