import * as Yup from "yup";
const networkOptions = [{ label: "Funq", value: "funq" }, {label: "Solana", value: "solana" }];

export const generateInputs = () => {
  const inputs = [
    {
      name: "email",
      label: "Email",
      type: "text",
      placeHolder: "Enter email",
      visibility: true,
    },
    {
      name: "network",
      label: "Network",
      type: "dropdown",
      placeHolder: "Select network",
      options: networkOptions,
      visibility: true,
    },
    {
      name: "amount",
      label: "Amount",
      type: "number",
      placeHolder: "Enter amount",
      visibility: true,
    },
  ];

  return inputs;
};

// Validation schema using Yup
export const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  // network: Yup.string().required("Network is required"), // Validate as a single string
  amount: Yup.number()
    .min(0.01, "Amount must be at least 0.01")
    .required("Amount is required"),
});

// Initial values for the form
export const initialValues = {
  email: "",
  network: "", // Initial value for network dropdown should be an empty string
  amount: "",
};
