import * as Yup from "yup";

export const generateInputs = () => {
  const inputs = [
    {
      name: "name",
      label: "Reward Name",
      type: "text",
      placeholder: "Enter reward name",
      visibility: true,
    },
    {
      name: "description",
      label: "Description",
      type: "text",
      placeholder: "Enter description",
      visibility: true,
    },
    {
      name: "count",
      label: "Count",
      type: "number",
      placeholder: "Enter count",
      visibility: true,
    },
  ];

  return inputs;
};

export const validationSchema = Yup.object().shape({
  name: Yup.string().required("Reward name is required"), // Validate reward name
  description: Yup.string(), // Optional field, no validation needed
  count: Yup.number()
    .required("Count is required")
    .min(0.01, "Count must be at least 0.01") // Ensure count is a positive number
    .typeError("Count must be a number"), // Ensure count is a number
});

export const initialValues = {
  name: "", // Initial value for reward name
  description: "", // Initial value for description
  count: "", // Initial value for count
};
