import {
  TOGGLE_LOADING,
  FETCH_REWARDS_SUCCESS,
  CREATE_REWARD_SUCCESS,
  ACTIVATE_REWARD_SUCCESS,
} from "../types";
import { post } from "services";
import { ACTIVATE_REWARD, CREATE_NEW_REWARD, LIST_ALL_REWARDS } from "graphql/reward";


export const fetchAllRewards = (variables) => {
  return async (dispatch) => {
    dispatch({ type: TOGGLE_LOADING, payload: true });

    try {
      const apiResponse = await post("", {
        query: LIST_ALL_REWARDS,
        variables: { reward: variables }, // Ensure variables is passed here
      });

      if (apiResponse.data && apiResponse.data.data.list_all_rewards) {
        const { data, hasMore } = apiResponse.data.data.list_all_rewards;

        dispatch({
          type: FETCH_REWARDS_SUCCESS,
          payload: { data, hasMore },
        });
      }
    } catch (error) {
      console.error("Error fetching rewards:", error);
    } finally {
      dispatch({ type: TOGGLE_LOADING, payload: false });
    }
  };
};


export const createNewReward = (rewardData) => {
  return async (dispatch) => {
    dispatch({ type: TOGGLE_LOADING, payload: true });

    try {
      const apiResponse = await post("", {
        query: CREATE_NEW_REWARD,
        variables: { reward: rewardData },
      });

      if (apiResponse.data && apiResponse.data.data.create_new_reward) {
        const { message } = apiResponse.data.data.create_new_reward;

        dispatch({
          type: CREATE_REWARD_SUCCESS,
          payload: message,
        });
      }
    } catch (error) {
      
    } finally {
      dispatch({ type: TOGGLE_LOADING, payload: false });
    }
  };
};

export const activateReward = (rewardId) => {
  return async (dispatch) => {
    dispatch({ type: TOGGLE_LOADING, payload: true });

    try {
      const apiResponse = await post("", {
        query: ACTIVATE_REWARD,
        variables: { reward: { _id: rewardId } },
      });

      if (apiResponse.data && apiResponse.data.activate_reward) {
        const { message } = apiResponse.data.activate_reward;

        dispatch({
          type: ACTIVATE_REWARD_SUCCESS,
          payload: { _id: rewardId, message },
        });
      }
    } catch (error) {
      console.error("Error activating reward:", error);
    } finally {
      dispatch({ type: TOGGLE_LOADING, payload: false });
    }
  };
};


