import { unAuthorizedPost } from "services";
import { history, isAlgo } from "utils";
import {
  LOGIN_SUCCESS,
  LOGOUT,
  TOGGLE_LOADING,
  TOGGLE_SIDEBAR,
} from "../types";
import { PASSWORD_LOGIN } from "graphql";

export const loginUser = (requestData) => {
  return async (dispatch) => {
    dispatch({ type: TOGGLE_LOADING, payload: true });
    dispatch({ type: TOGGLE_SIDEBAR, payload: false });

    let apiResponse = await unAuthorizedPost("", {
      query: PASSWORD_LOGIN,
      variables: requestData,
    });

    if (apiResponse.data.customStatus) {
      let {
        login_with_password: { token },
      } = apiResponse.data.data;

      dispatch({ type: LOGIN_SUCCESS, payload: token });
      history.push(isAlgo() ? "/app/dashboard?version=algo" : "/app/dashboard");
    }

    dispatch({ type: TOGGLE_LOADING, payload: false });
  };
};

export const logoutUser = () => {
  return (dispatch) => {
    dispatch({ type: LOGOUT });
  };
};
