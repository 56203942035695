import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Box } from "@material-ui/core";

import CustomHeader from "components/Headers/CustomHeader";

import componentStyles from "assets/theme/views/admin/tables.js";
import CustomTable from "components/CustomTable/CustomTable";

import { fetchAllwallets } from "store/actions/walletAction";

const useStyles = makeStyles(componentStyles);

function Wallets() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { list: walletsData } = useSelector(({ wallets }) => ({
    list: wallets.list,
  }));

  useEffect(() => {
    dispatch(fetchAllwallets());
  }, [dispatch]);

  return (
    <>
      <CustomHeader />
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
        <br />
        <CustomTable
          title="Wallets"
          columns={[
            { title: "Wallet Address", field: "wallet_address" },
            { title: "Network", field: "network" },
            {
              title: "Linked to",
              render: (rowData) => <p>{rowData?.user_id?.email}</p>,
            },
            {
              title: "Amount",
              field: "amount",
            },
          ]}
          data={walletsData}
        />
      </Container>
    </>
  );
}

export default Wallets;
