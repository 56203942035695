import { TOGGLE_LOADING, FETCH_USERS_SUCCESS,SEND_TOKENS_SUCCESS  } from "../types";

const initialState = {
  list: [],
  loading: false,
  error: null,
  sendTokensMessage: null,
};

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_LOADING:
      return { ...state, loading: action.payload };
    case FETCH_USERS_SUCCESS:
      return {
        ...state,
        list: action.payload,
        error: null
      };
      case SEND_TOKENS_SUCCESS:
        return {
          ...state,
          sendTokensMessage: action.payload,
          error: null,
        };
        
   
    default:
      return state;
  }
};

export default usersReducer;
