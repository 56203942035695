import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Box } from "@material-ui/core";

import CustomHeader from "components/Headers/CustomHeader";
import componentStyles from "assets/theme/views/admin/tables.js";
import CustomTable from "components/CustomTable/CustomTable";

import { fetchQuizTopics } from "store/actions/topicAction"; // Adjust import based on your file structure

const useStyles = makeStyles(componentStyles);

function Topics() {
  const classes = useStyles();
  const dispatch = useDispatch();

  // Extract quiz topics from the Redux store
  const {
    data: quizTopicsData,
    loading,
    error,
  } = useSelector(({ topic }) => ({
    data: topic.topic,
    loading: topic.loading,
    error: topic.error,
  }));

  useEffect(() => {
    dispatch(fetchQuizTopics());
  }, [dispatch]);

  return (
    <>
      <CustomHeader />
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
        <br />
        {loading && <p>Loading...</p>}
        {error && <p>Error: {error}</p>}
        {!loading && !error && (
          <CustomTable
            title="Quiz Topics"
            columns={[
              { title: "Topic Name", field: "name" },
              {
                title: "Active",
                render: (rowData) => (
                  <p>{rowData.is_active ? "Active" : "Inactive"}</p>
                ),
              },
            ]}
            data={quizTopicsData}
          />
        )}
      </Container>
    </>
  );
}

export default Topics;
