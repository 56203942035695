export const LIST_ALL_QUIZ_QUESTIONS = `
  query ListAllQuizQuestions($quiz: GetAllQuizQuestionsDto!) {
    list_all_quiz_questions(quiz: $quiz) {
      data {
        question
        options
        answer
        _id
        topic_id {
          _id
          name
        }
      }
      hasMore
    }
  }
`;

export const GENERATE_QUIZ_QUESTIONS = `
  mutation GenerateQuizQuestions($quiz: QuizQuestionDto!) {
    generate_quiz_questions(quiz: $quiz) {
      data {
        _id
        question
        options
        answer
        topic_id {
          _id
          name
        }
      }
    }
  }
`;

export const DELETE_QUIZ_QUESTION = `
  mutation deleteQuizQuestion($quiz: IdOnlyDto!) {
    delete_quiz_question(quiz: $quiz) {
      message
    }
  }
`;

export const UPDATE_QUIZ_QUESTION = `
  mutation updateQuizQuestion($quiz: UpdateQuizQuestionDto!) {
    update_quiz_question(quiz: $quiz) {
      message
    }
  }
`;




