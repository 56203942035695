import React, { useState } from "react";
import Papa from "papaparse";
import { Button, Input, Box } from "@material-ui/core";
import { saveAs } from "file-saver"; // Import file-saver for downloading files

const CSVUpload = ({ onUpload }) => {
  const [file, setFile] = useState(null);
  const [error, setError] = useState("");

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && selectedFile.type !== "text/csv") {
      setError("Please upload a valid CSV file.");
      return;
    }
    setError("");
    setFile(selectedFile);
  };

  const handleUpload = () => {
    if (!file) {
      setError("No file selected.");
      return;
    }

    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: (results) => {
        const quizData = results.data.map((row) => ({
          question: row.question || "",
          options: [
            row.option1 || "",
            row.option2 || "",
            row.option3 || "",
            row.option4 || "",
          ],
          answer: row.answer || "",
          topic: row.topic || "",
        }));

        const invalidQuestions = quizData.filter((q) => !q.topic);
        if (invalidQuestions.length > 0) {
          setError(
            "There are some missing topics in the questions. Please make sure that each question has a subject."
          );
          return;
        }

        // If no invalid questions, proceed
        setError("");

        onUpload(quizData);
      },
      error: (error) => {
        console.error("CSV Parsing Error:", error.message);
        setError("Error parsing CSV file.");
      },
    });
  };

  const downloadSampleCSV = () => {
    const sampleData = [
      [
        "question",
        "option1",
        "option2",
        "option3",
        "option4",
        "answer",
        "topic",
      ],
      [
        "What is the capital of France?",
        "Paris",
        "London",
        "Berlin",
        "Madrid",
        "Paris",
        "Geography",
      ],
      ["What is 2 + 2?", "3", "4", "5", "6", "4", "Mathematics"],
      [
        "Who wrote 'To Kill a Mockingbird'?",
        "Harper Lee",
        "Mark Twain",
        "Ernest Hemingway",
        "F. Scott Fitzgerald",
        "Harper Lee",
        "Literature",
      ],
    ];

    const csv = Papa.unparse(sampleData);

    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, "sample-quiz-data.csv");
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" p={2}>
      <Box display="flex" alignItems="center" mb={2}>
        <Input
          type="file"
          accept=".csv"
          onChange={handleFileChange}
          inputProps={{
            style: {
              fontSize: "0.875rem",
              padding: "6px 12px",
              height: "2.5em",
              lineHeight: "1.5em",
              width: "100%",
            },
          }}
        />
        <Button
          onClick={handleUpload}
          variant="contained"
          color="primary"
          size="small"
          style={{ marginLeft: "10px" }}
        >
          Upload CSV
        </Button>
      </Box>
      <Button
        onClick={downloadSampleCSV}
        variant="outlined"
        color="secondary"
        size="small"
      >
        Download Sample CSV
      </Button>
      {error && <div style={{ color: "red", marginTop: "10px" }}>{error}</div>}
    </Box>
  );
};

export default CSVUpload;
