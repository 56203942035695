export const CREATE_NEW_CAMPAIGN = `
  mutation add_new_campaign($campaign: AddNewCampaignDto!) {
    add_new_campaign(campaign: $campaign) {
      message
    }
  }
`;


export const LIST_ALL_CAMPAIGNS = `
  query list_all_campaigns($campaign: GetAllCampaignsDto!) {
    list_all_campaigns(campaign: $campaign) {
      data {
        _id
        name
        description
        is_active
        start_date
        end_date
        campaign_code
        qr_img
        qr_url
      }
      hasMore
    }
  }
`;


export const ACTIVATE_CAMPAIGN = `
  mutation activate_campaign($campaign: IdOnlyDto!) {
    activate_campaign(campaign: $campaign) {
      message
    }
  }
`;

export const GET_CAMPAIGN_DETAILS = `
  query campaign_details($campaign: IdOnlyDto!) {
    campaign_details(campaign: $campaign) {
      data {
        _id
        name
        description
        is_active
        start_date
        end_date
        campaign_code
        qr_img
        qr_url
      }
    }
  }
`;

export const UPDATE_CAMPAIGN = `
  mutation update_campaign($campaign: UpdateCampaignDto!) {
    update_campaign(campaign: $campaign) {
      message
    }
  }
`;

export const LIST_ALL_CAMPAIGN_GAMES = `
  query list_all_campaign_games($campaign: GetAllCampaignGamesDto!) {
    list_all_campaign_games(campaign: $campaign) {
      data {
        _id
        name
        game_code
        is_active
        total_levels
      }
    }
  }
`;
