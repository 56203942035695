import {
  TOGGLE_LOADING,
  FETCH_ANALYTICS_SUCCESS,

} from "../types"; // Import your action types

const initialState = {
  analyticsList: [],
  loading: false,
  error: null,
};

const analyticsReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_LOADING:
      return { ...state, loading: action.payload };

    case FETCH_ANALYTICS_SUCCESS:
      return {
        ...state,
        analyticsList: action.payload,
        error: null,
      };


    default:
      return state;
  }
};

export default analyticsReducer;
