import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Box, Grid, Card } from "@material-ui/core";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import CustomHeader from "components/Headers/CustomHeader";
import componentStyles from "assets/theme/views/admin/tables.js";
import CustomTable from "components/CustomTable/CustomTable";
import CustomIconButton from "components/CustomButton/CustomIconButton";
import CustomSidebar from "components/CustomSidebar/CustomSidebar";
import CustomDialogBox from "components/CustomDialogBox/CustomDialogBox";
import { toggleSidebar } from "store/actions";
import { fetchAllUsers, sendTokensToEmail } from "store/actions/userAction";
import {
  generateInputs,
  validationSchema,
  initialValues,
} from "./send_token_email"; // Adjust import as per your file structure
import CustomTextButton from "components/CustomButton/CustomTextButton";
import { toggleModal } from "store/actions";
import {
  generateInputs1,
  validationSchema1,
  initialValues1,
} from "./send_token_user";
import { sendTokensToUser } from "store/actions/userAction";
import { Send } from "@material-ui/icons";

const useStyles = makeStyles(componentStyles);

const Users = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [selectedUser, setSelectedUser] = useState("");
  const [sidebarInputs, setSidebarInputs] = useState([]);
  const [refreshPage, setRefreshPage] = useState(false);
  const [actionType, setActionType] = useState(
    localStorage.getItem("actionType") || ""
  );

  const handleSendEmail = () => {
    setActionType("sendEmail");
    setSidebarInputs(generateInputs());
    dispatch(toggleSidebar(true));
  };

  const handleSendToken = () => {
    setActionType("sendToken");
    setSidebarInputs(generateInputs1());
    dispatch(toggleSidebar(true));
  };

  const {
    list: userData,
    loading,
    error,
  } = useSelector(({ users }) => ({
    list: users.list,
    loading: users.loading,
    error: users.error,
  }));

  useEffect(() => {
    dispatch(fetchAllUsers());
  }, [dispatch, refreshPage]);

  useEffect(() => {
    if (actionType) {
      localStorage.setItem("actionType", actionType);
    }
  }, [actionType]);

  // const handleDialogBoxSubmit = () => {
  //   setShowDialog(false);
  //   dispatch(toggleSidebar(true));
  // };

  const handleSubmit = (values) => {
    const { email, network, amount } = values;
    const networkValue = network.length ? network[0]?.value : "";

    if (actionType === "sendEmail") {
      dispatch(
        sendTokensToEmail({
          email,
          network: networkValue,
          amount: parseFloat(amount),
        })
      )
        .then(() => {
          setRefreshPage((prev) => !prev);
          dispatch(toggleSidebar(false));
        })
        .catch((error) => {
          console.error("Error sending tokens:", error);
        });
    } else if (actionType === "sendToken") {
      dispatch(
        sendTokensToUser({
          network: networkValue,
          amount: parseFloat(amount),
          _id: selectedUser,
        })
      )
        .then(() => {
          setRefreshPage((prev) => !prev);
          dispatch(toggleSidebar(false));
        })
        .catch((error) => {
          console.error("Error sending tokens:", error);
        });
    }
  };

  const handleSidebarClose = () => {
    localStorage.removeItem("actionType");
    dispatch(toggleSidebar(false));
  };
  return (
    <>
      <CustomHeader />
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
        <Grid container justify="flex-start" spacing={3}>
          <Grid item style={{ width: "100%" }}>
            <Card style={{ backgroundColor: "grey" }}>
              <Grid container spacing={3}>
                <Grid item style={{ padding: "1.5em" }}>
                  <CustomIconButton
                    title="Send Token"
                    icon={<Send />}
                    onClick={handleSendEmail}
                    style={{ width: "100%" }}
                  />
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
        <br />
        <CustomTable
          title="Users"
          columns={[
            {
              title: "Actions",
              render: (rowData) => (
                <CustomTextButton
                  color="primary"
                  variant="outlined"
                  onClick={() => {
                    setSelectedUser(rowData._id);
                    handleSendToken();
                  }}
                  title="Send Token"
                  size="small"
                />
              ),
            },
            { title: "Name", field: "name" },
            { title: "Email", field: "email" },
          ]}
          data={userData}
          loading={loading}
          error={error}
        />
      </Container>
      <CustomSidebar
        title="Send Message Token"
        inputs={sidebarInputs}
        validationSchema={
          actionType === "sendEmail" ? validationSchema : validationSchema1
        }
        initialValues={
          actionType === "sendEmail" ? initialValues : initialValues1
        }
        handleSubmit={handleSubmit} // Handle form submit to send message token
        handleClose={handleSidebarClose}
      />
      {/* <CustomDialogBox
        open={showDialog}
        handleClose={() => setShowDialog(false)}
        title="Are you sure you want to continue?"
        handleSubmitButton={handleDialogBoxSubmit}
      /> */}
    </>
  );
};

export default Users;
