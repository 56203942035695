// reducers/rewardsReducer.js
import {
  TOGGLE_LOADING,
  FETCH_REWARDS_SUCCESS,
  CREATE_REWARD_SUCCESS,
  ACTIVATE_REWARD_SUCCESS,
} from "../types";

// reducers/rewardsReducer.js
const initialState = {
  data: [],
  hasMore: false,
  loading: false,
  error: null,
};


const rewardsReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    case FETCH_REWARDS_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        hasMore: action.payload.hasMore,
        loading: false,
        error: null,
      };

      case CREATE_REWARD_SUCCESS:
      return {
        ...state,
        message: action.payload,
        loading: false,
      };
      case ACTIVATE_REWARD_SUCCESS:
      return {
        ...state,
        rewards: state.rewards.map((reward) =>
          reward.id === action.payload.id
            ? { ...reward, is_active: true }
            : reward
        ),
      };

    default:
      return state;
  }
};

export default rewardsReducer;
