import * as Yup from "yup";
// import { parseISO, isValid } from "date-fns";

export const generateInputs = () => {
  const inputs = [
    {
      name: "name",
      label: "Campaign Name",
      type: "text",
      placeholder: "Enter campaign name",
      visibility: true,
      required:true,
    },
    {
      name: "description",
      label: "Description",
      type: "text",
      placeholder: "Enter description",
      visibility: true,
      required:false,
    },
    {
      name: "start_date",
      label: "Start Date",
      type: "datePicker",
      placeholder: "Select start date",
      visibility: true,
      required:false,
    },
    {
      name: "end_date",
      label: "End Date",
      type: "datePicker",
      placeholder: "Select end date",
      visibility: true,
      required:false,
    },
  ];

  return inputs;
};

export const validationSchema = Yup.object().shape({
  name: Yup.string().required(),
  description: Yup.string().optional(),
//   start_date: Yup.date()
//     .optional()
//     .nullable()
//     .test(
//       "is-valid-date",
//       "Start date must be a valid date",
//       (value) => !value || isValid(new Date(value))
//     ),
//   end_date: Yup.date()
//     .optional()
//     .nullable()
//     .test(
//       "is-valid-date",
//       "End date must be a valid date",
//       (value) => !value || isValid(new Date(value))
//     ),
});

export const initialValues = {
  name: "",
  description: "",
  start_date: "",
  end_date: "",
};
