import { post } from "services"; // Update with your API service import
import { TOGGLE_LOADING, GENERATE_QUIZ_SUCCESS, FETCH_QUIZ_SUCCESS, FETCH_QUIZ_FAILURE, RESET_QUIZ_DATA, DELETE_QUIZ_SUCCESS, UPDATE_QUIZ_SUCCESS } from "../types"; // Update with your action types
import { DELETE_QUIZ_QUESTION, GENERATE_QUIZ_QUESTIONS, UPDATE_QUIZ_QUESTION } from "graphql/question"; // Import the GraphQL query

import { LIST_ALL_QUIZ_QUESTIONS } from "graphql/question"; // Import the GraphQL query

export const fetchQuizQuestions = (pageNumber, pageSize) => {
  return async (dispatch) => {
    dispatch({ type: TOGGLE_LOADING, payload: true });

    try {
      const quizDto = { page_number: pageNumber, page_size: pageSize };

      const apiResponse = await post("/graphql", {
        query: LIST_ALL_QUIZ_QUESTIONS,
        variables: { quiz: quizDto },
      });

      if (apiResponse.data && apiResponse.data.data.list_all_quiz_questions) {
        const { data, hasMore } = apiResponse.data.data.list_all_quiz_questions;

        dispatch({
          type: FETCH_QUIZ_SUCCESS,
          payload: { data, hasMore },
        });
      } else {
        throw new Error("Failed to fetch quiz questions");
      }
    } catch (error) {
      dispatch({
        type: FETCH_QUIZ_FAILURE,
        payload: error.message,
      });
      console.error("Error fetching quiz questions:", error);
    } finally {
      dispatch({ type: TOGGLE_LOADING, payload: false });
    }
  };
};
export const generateQuizQuestions = (quizDto) => {
  return async (dispatch) => {
    dispatch({ type: TOGGLE_LOADING, payload: true });

    try {
      const apiResponse = await post("/graphql", {
        query: GENERATE_QUIZ_QUESTIONS,
        variables: {
          quiz: quizDto,
        },
      });
      console.log("API Response:", apiResponse);

      if (apiResponse.data && apiResponse.data.data.generate_quiz_questions) {
        const questions = apiResponse.data.data.generate_quiz_questions.data;
        dispatch({
          type: GENERATE_QUIZ_SUCCESS,
          payload: questions,
        });
      } else {
        throw new Error("Failed to generate quiz questions"); // Throw an error if response doesn't match expected format
      }
    } catch (error) {
      console.error("Error generating quiz questions:", error);
    } finally {
      dispatch({ type: TOGGLE_LOADING, payload: false });
    }
  };
};

export const resetQuizData = () => {
  return async (dispatch) => {
    dispatch({
      type: RESET_QUIZ_DATA,
    });
  };
};

export const deleteQuizQuestion = (id) => {
  return async (dispatch) => {
    dispatch({ type: TOGGLE_LOADING, payload: true });

    try {
      if (!id) {
        throw new Error("ID is required for deletion.");
      }

      const response = await post("/graphql", {
        query: DELETE_QUIZ_QUESTION,
        variables: { quiz: { _id: id } },
      });

      const { data } = response;
      if (data && data.data.delete_quiz_question) {
        dispatch({
          type: DELETE_QUIZ_SUCCESS,
          payload: id, // Only ID should be in payload
        });
      } else {
        throw new Error("Failed to delete quiz question");
      }
    } catch (error) {
      console.error("Error deleting quiz question:", error);
    } finally {
      dispatch({ type: TOGGLE_LOADING, payload: false });
    }
  };
};


export const updateQuizQuestion = (quizDto) => {
  return async (dispatch) => {
    dispatch({ type: TOGGLE_LOADING, payload: true });

    try {
      const response = await post("/graphql", {
        query: UPDATE_QUIZ_QUESTION,
        variables: { quiz: quizDto },
      });

      const { data } = response;
      if (data && data.data.update_quiz_question) {
        dispatch({
          type: UPDATE_QUIZ_SUCCESS,
          payload: { quiz: quizDto, message: data.data.update_quiz_question.message },
        });
      } else {
        throw new Error("Failed to update quiz question");
      }
    } catch (error) {
      console.error("Error updating quiz question:", error);
    } finally {
      dispatch({ type: TOGGLE_LOADING, payload: false });
    }
  };
};
