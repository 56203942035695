import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
} from "@material-ui/core";
import { updateQuizQuestion } from "store/actions/quizquestionAction"; // Update path as needed
import { useDispatch, useSelector } from "react-redux";
import { fetchQuizTopics } from "store/actions/topicAction"; // Update path as needed

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  height: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  overflowY: "auto", // Allow scrolling if content overflows
};

const UpdateQuestionModal = ({ open, onClose, question }) => {
  const [formData, setFormData] = useState({
    _id: "",
    question: "",
    options: "",
    answer: "",
    topic_id: "",
  });

  const dispatch = useDispatch();

  const { topic, loading, error } = useSelector((state) => state.topic); // Adjust according to your state structure

  useEffect(() => {
    if (open) {
      dispatch(fetchQuizTopics()); // Fetch topics when the modal opens
    }
  }, [open, dispatch]);

  useEffect(() => {
    if (question) {
      setFormData({
        _id: question._id || "",
        question: question.question || "",
        options: question.options.join(", ") || "", // Convert options to string
        answer: question.answer || "",
        topic_id: question.topic_id?._id || "",
      });
    }
  }, [question]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleUpdate = () => {
    if (
      !formData.question ||
      !formData.options ||
      !formData.answer ||
      !formData.topic_id
    ) {
      console.error("All fields are required.");
      return;
    }

    dispatch(
      updateQuizQuestion({
        ...formData,
        options: formData.options.split(",").map((option) => option.trim()), // Convert string back to array
      })
    )
      .then(() => {
        onClose(); // Close the modal after updating
      })
      .catch((error) => {
        console.error("Error updating question:", error);
      });
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={modalStyle}>
        <Typography variant="h6" component="h2">
          Update Question
        </Typography>
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <FormControl fullWidth margin="normal">
              <InputLabel id="topic-select-label">Topic</InputLabel>
              <Select
                labelId="topic-select-label"
                name="topic_id"
                value={formData.topic_id}
                onChange={handleChange}
                displayEmpty
                variant="filled"
              >
                <MenuItem value="" disabled>
                  Select a topic
                </MenuItem>
                {topic.length > 0 ? (
                  topic.map((topicItem) => (
                    <MenuItem key={topicItem._id} value={topicItem._id}>
                      {topicItem.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No topics available</MenuItem>
                )}
              </Select>
            </FormControl>
            <TextField
              fullWidth
              margin="normal"
              name="question"
              label="Question"
              value={formData.question}
              onChange={handleChange}
            />
            <TextField
              fullWidth
              margin="normal"
              name="options"
              label="Options "
              value={formData.options}
              onChange={handleChange}
            />
            <TextField
              fullWidth
              margin="normal"
              name="answer"
              label="Answer"
              value={formData.answer}
              onChange={handleChange}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleUpdate}
              style={{ marginRight: "10px" }}
              size="small"
            >
              Update
            </Button>
            <Button
              variant="outlined"
              color="default"
              onClick={onClose}
              size="small"
            >
              Cancel
            </Button>
          </>
        )}
        {error && (
          <Typography color="error" variant="body2" sx={{ mt: 2 }}>
            {error}
          </Typography>
        )}
      </Box>
    </Modal>
  );
};

export default UpdateQuestionModal;
