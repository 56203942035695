import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Box,
  CircularProgress,
  Typography,
} from "@material-ui/core";

import CustomHeader from "components/Headers/CustomHeader";
import componentStyles from "assets/theme/views/admin/tables.js";
import CustomTable from "components/CustomTable/CustomTable";
import { fetchAllledger } from "store/actions/walletAction";

const useStyles = makeStyles(componentStyles);

function Ledger_Wallets() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { list1: ledgerData } = useSelector(({ ledger }) => ({
    list1: ledger.list1,
  }));

  useEffect(() => {
    dispatch(fetchAllledger());
  }, [dispatch]);

  const filteredLedgerData = ledgerData.filter(
    (item) => item.network !== "funq"
  );

  return (
    <>
      <CustomHeader />
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
        <br />
        <CustomTable
          title="Wallets"
          columns={[
            { title: "Wallet Address", field: "wallet_address" },
            { title: "Network", field: "network" },
            { title: "Balance", field: "balance" },
          ]}
          data={filteredLedgerData}
        />
      </Container>
    </>
  );
}

export default Ledger_Wallets;
