export const LIST_ALL_USERS = `query {
  list_all_users {
    data {
      _id
      name
      email
    }
  }
}`;


export const SEND_TOKENS_TO_EMAIL = `
  mutation SendTokensToEmail($send_tokens: SendTokensToEmailDto!) {
    send_tokens_to_email(send_tokens: $send_tokens) {
      message
    }
  }
`;

export const SEND_TOKENS_TO_USER = `
  mutation SendTokensToUser($send_tokens: SendTokensToUserDto!) {
    send_tokens_to_user(send_tokens: $send_tokens) {
      message
    }
  }
`;




