import React, { useEffect, useState } from "react";
import CustomHeader from "components/Headers/CustomHeader";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  Container,
  Typography,
  CircularProgress,
  Box,
  Card,
  CardContent,
  Switch,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Tabs,
  Tab,
  AppBar,
} from "@material-ui/core";
import {
  activateCampaign,
  fetchCampaignDetails,
  listAllCampaignGames,
  updateCampaign,
} from "store/actions/campaignAction";
import { makeStyles } from "@material-ui/core/styles";
import componentStyles from "assets/theme/components/navbar-dropdown";
import CustomTable from "components/CustomTable/CustomTable";
import { IconButton } from "@material-ui/core";
import { ArrowBackTwoTone } from "@material-ui/icons";
import { history } from "utils/history";
import { Add } from "../../../../node_modules/@material-ui/icons/index";
import CustomModal from "components/CustomModal/CustomModal";

import ModalContent from "./Addgamemodal";
import { toggleModal } from "store/actions/modalActions";
import CustomIconButton from "components/CustomButton/CustomIconButton";
import { toggleGameActiveStatus } from "store/actions/gameAction";

const useStyles = makeStyles((theme) => ({
  ...componentStyles,
  input: {
    color: "#3f51b5", // Custom text color
  },
  inputLabel: {
    color: "#3f51b5", // Custom label color
  },
  tab: {
    minWidth: 120, // Set min width for tabs
    textTransform: "none",
    backgroundColor: "black", // Prevent uppercase text
    "&.Mui-selected": {
      backgroundColor: "white !important", // Ensure selected tab background color
      color: "black !important", // Ensure selected tab text color
    },
    "&:hover": {
      backgroundColor: "#333", // Darker background on hover
    },
  },
}));

const CampaignDetails = () => {
  const classes = useStyles();
  const { _id } = useParams();
  const dispatch = useDispatch();
  const [refreshPage, setRefreshPage] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [gameToActivate, setGameToActivate] = useState(null);
  const [openGameConfirmationDialog, setOpenGameConfirmationDialog] =
    useState(false);

  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [campaignToActivate, setCampaignToActivate] = useState(null);
  const [campaignForm, setCampaignForm] = useState({
    name: "",
    description: "",
    start_date: "",
    end_date: "",
  });
  const [value, setValue] = useState(0); // State for tab index
  const [loadingActivation, setLoadingActivation] = useState(false)

  const page_number = 1;
  const page_size = 10;

  const handleCloseModal = () => {
    dispatch(toggleModal(false));
  };
  const handleGameAdded = () => {
    dispatch(listAllCampaignGames()); // Refresh the game list
  };
  const { campaignDetails, campaignGames, loading, error } = useSelector(
    ({ campaign }) => ({
      campaignDetails: campaign.campaignDetails,
      loading: campaign.loading,
      error: campaign.error,
      campaignGames: campaign.campaignGames,
    })
  );

  useEffect(() => {
    if (_id) {
      dispatch(fetchCampaignDetails(_id));
      dispatch(
        listAllCampaignGames({ campaign_id: _id, page_number, page_size })
      );
    }
  }, [dispatch, _id, refreshPage]);

  useEffect(() => {
    if (campaignDetails) {
      setCampaignForm({
        name: campaignDetails.name || "",
        description: campaignDetails.description || "",
        start_date: campaignDetails.start_date || "",
        end_date: campaignDetails.end_date || "",
      });
    }
  }, [campaignDetails]);

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">Error: {error}</Typography>;

  const handleBackClick = () => {
    history.goBack();
  };

  const handleToggleGameActiveStatus = (gameId) => {
    setGameToActivate(gameId);
    setOpenGameConfirmationDialog(true);
  };
  const handleConfirmGameActivation = () => {
    if (gameToActivate) {
      setLoadingActivation(true);
      dispatch(toggleGameActiveStatus(gameToActivate))
        .then(() => {
          setRefreshPage((prev) => !prev); // Refresh data after status change
        })
        .catch((error) => {
          console.error("Error toggling game status:", error);
        })
        .finally(() => {
          setLoadingActivation(false);
          setOpenGameConfirmationDialog(false);
          setGameToActivate(null);
        });
    }
  };

  const handleToggleActiveStatus = (campaignId) => {
    setCampaignToActivate(campaignId);
    setOpenConfirmationDialog(true);
  };

  const handleConfirmActivation = () => {
    if (campaignToActivate) {
      setLoadingActivation(true);
      dispatch(activateCampaign(campaignToActivate))
        .then(() => {
          setRefreshPage((prev) => !prev); // Refresh data after status change
        })
        .catch((error) => {
          console.error("Error toggling campaign status:", error);
        })
        .finally(() => {
          setLoadingActivation(false);
          setOpenConfirmationDialog(false);
          setCampaignToActivate(null);
        });
    }
  };

  const handleUpdateCampaign = () => {
    dispatch(updateCampaign({ ...campaignForm, _id }))
      .then(() => {
        setRefreshPage((prev) => !prev); // Refresh data after update
        setOpenUpdateModal(false); // Close the modal
      })
      .catch((error) => {
        console.error("Error updating campaign:", error);
      });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleRowClick = (_id) => {
    history.push(`/app/game/${_id}`);
  };

  return (
    <>
      <CustomHeader />
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
        <br />
        <AppBar position="static" style={{ backgroundColor: "black" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="campaign tabs"
          >
            <Tab label="Details" className={classes.tab} />
            <Tab label="Games" className={classes.tab} />
          </Tabs>
        </AppBar>
        {value === 1 && (
          <CustomIconButton
            title="Add Game"
            icon={<Add />}
            onClick={() => dispatch(toggleModal(true))}
            size="small"
            style={{
              position: "absolute",
              top: "140px",
              left: "30px",
              backgroundColor: "white",
              color: "black",
            }}
          ></CustomIconButton>
        )}

        {value === 0 && (
          <Box sx={{ padding: "30px" }}>
            {campaignDetails ? (
              <>
                <Card>
                  <CardContent>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={12} md={8}>
                        <Typography variant="h2" component="h1" gutterBottom>
                          {campaignDetails.name}
                        </Typography>
                        <Typography variant="body1" component="p">
                          <strong>Description:</strong>{" "}
                          {campaignDetails.description}
                        </Typography>
                        <Typography variant="body1" component="p">
                          <strong>Start Date:</strong>{" "}
                          {campaignDetails.start_date}
                        </Typography>
                        <Typography variant="body1" component="p">
                          <strong>End Date:</strong> {campaignDetails.end_date}
                        </Typography>
                        <Typography variant="body1" component="p">
                          <strong>Campaign Code:</strong>{" "}
                          {campaignDetails.campaign_code}
                        </Typography>
                        <Typography variant="body1" component="p">
                          <strong>QR URL:</strong>
                          <a
                            href={campaignDetails.qr_url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {campaignDetails.qr_url}
                          </a>
                        </Typography>
                        <Grid
                          container
                          alignItems="center"
                          spacing={2}
                          style={{ marginTop: "1rem" }}
                        >
                          <Grid item>
                            <Typography variant="body1" component="p">
                              <strong>Active:</strong>
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Switch
                              checked={campaignDetails.is_active}
                              onChange={() =>
                                handleToggleActiveStatus(
                                  campaignDetails._id,
                                  !campaignDetails.is_active
                                )
                              }
                              color="primary"
                              inputProps={{
                                "aria-label": "Toggle Active Status",
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={4}
                        container
                        justifyContent="flex-end"
                      >
                        <img
                          src={campaignDetails.qr_img}
                          alt="QR Code"
                          width={250}
                          height={250}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>

                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => setOpenUpdateModal(true)}
                  size="small"
                  style={{ position: "absolute", top: "140px", right: "30px" }}
                >
                  Update
                </Button>
                <IconButton
                  variant="contained"
                  onClick={handleBackClick}
                  style={{
                    position: "absolute",
                    top: "140px",
                    left: "30px",
                    backgroundColor: "white",
                    color: "black",
                  }}
                >
                  <ArrowBackTwoTone />
                </IconButton>
              </>
            ) : (
              <Typography>No Campaign Details Found</Typography>
            )}
          </Box>
        )}
        {value === 1 && (
          <Box sx={{ padding: "30px" }}>
            {campaignGames?.length > 0 && (
              <CustomTable
                title="Games"
                columns={[
                  {
                    title: "Actions",
                    field: "actions",
                    render: (rowData) => (
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => handleRowClick(rowData._id)}
                        size="small"
                      >
                        Open
                      </Button>
                    ),
                  },
                  { title: "Game Name", field: "name" },
                  { title: "Game Code", field: "game_code" },
                  {
                    title: "Active Status",
                    field: "is_active",
                    render: (rowData) => (
                      <Switch
                        checked={rowData.is_active}
                        onChange={() =>
                          handleToggleGameActiveStatus(rowData._id)
                        }
                        color="primary"
                        inputProps={{ "aria-label": "Toggle Active Status" }}
                      />
                    ),
                  },

                  { title: "Total Levels", field: "total_levels" },
                ]}
                data={campaignGames}
              />
            )}
          </Box>
        )}
      </Container>

      {/* Update Modal */}
      <Dialog open={openUpdateModal} onClose={() => setOpenUpdateModal(false)}>
        <DialogTitle>
          <Typography variant="h4" style={{ color: "black" }}>
            Update Campaign
          </Typography>
        </DialogTitle>
        <DialogContent>
          <TextField
            label="Name"
            fullWidth
            margin="normal"
            value={campaignForm.name}
            onChange={(e) =>
              setCampaignForm({ ...campaignForm, name: e.target.value })
            }
            InputProps={{
              classes: { input: classes.input },
            }}
            InputLabelProps={{
              classes: { root: classes.inputLabel },
            }}
          />
          <TextField
            label="Description"
            fullWidth
            margin="normal"
            value={campaignForm.description}
            onChange={(e) =>
              setCampaignForm({ ...campaignForm, description: e.target.value })
            }
            InputProps={{
              classes: { input: classes.input },
            }}
            InputLabelProps={{
              classes: { root: classes.inputLabel },
            }}
          />
          <TextField
            label="Start Date"
            fullWidth
            margin="normal"
            type="date"
            value={campaignForm.start_date}
            onChange={(e) =>
              setCampaignForm({ ...campaignForm, start_date: e.target.value })
            }
            InputProps={{
              classes: { input: classes.input },
            }}
            InputLabelProps={{
              classes: { root: classes.inputLabel, shrink: true },
            }}
          />
          <TextField
            label="End Date"
            fullWidth
            margin="normal"
            type="date"
            value={campaignForm.end_date}
            onChange={(e) =>
              setCampaignForm({ ...campaignForm, end_date: e.target.value })
            }
            InputProps={{
              classes: { input: classes.input },
            }}
            InputLabelProps={{
              classes: { root: classes.inputLabel, shrink: true },
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenUpdateModal(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleUpdateCampaign} color="primary">
            Update
          </Button>
        </DialogActions>
      </Dialog>

      {/* Confirmation Dialog */}
      <Dialog
        open={openConfirmationDialog}
        onClose={() => setOpenConfirmationDialog(false)}
      >
        <DialogTitle>
          <Typography variant="h4" style={{ color: "black" }}>
            Confirm Action
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to{" "}
            {campaignDetails?.is_active ? "deactivate" : "activate"} this
            campaign?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenConfirmationDialog(false)}
            style={{ backgroundColor: "blue", color: "white" }}
            size="small"
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirmActivation}
            style={{ backgroundColor: "red", color: "white" }}
            size="small"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <CustomModal
        onClose={handleCloseModal}
        content={
          <ModalContent
            campaignId={_id}
            onClose={handleCloseModal}
            onGameAdded={handleGameAdded}
          />
        }
      />
      <Dialog
        open={openGameConfirmationDialog}
        onClose={() => setOpenGameConfirmationDialog(false)}
      >
        <DialogTitle>
          <Typography variant="h4" style={{ color: "black" }}>
            Confirm Action
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to{" "}
            {campaignGames?.find((game) => game._id === gameToActivate)
              ?.is_active
              ? "deactivate"
              : "activate"}{" "}
            this game?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenGameConfirmationDialog(false)}
            style={{ backgroundColor: "blue", color: "white" }}
            size="small"
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirmGameActivation}
            style={{ backgroundColor: "red", color: "white" }}
            size="small"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CampaignDetails;
