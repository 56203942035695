import {
  TOGGLE_LOADING,
  FETCH_QUIZ_TOPICS_SUCCESS,
  FETCH_QUIZ_TOPICS_FAILURE,
} from "../types";
import { post } from "services";
import { GET_ALL_QUIZ_TOPICS } from "graphql/topic";

export const fetchQuizTopics = () => {
  return async (dispatch) => {
    dispatch({ type: TOGGLE_LOADING, payload: true });

    try {
      const apiResponse = await post("", {
        query: GET_ALL_QUIZ_TOPICS,
        variables: {},
      });

      if (apiResponse.data && apiResponse.data.data.list_all_quiz_topics) {
        const { data } = apiResponse.data.data.list_all_quiz_topics;

        dispatch({
          type: FETCH_QUIZ_TOPICS_SUCCESS,
          payload: data,
        });
      }
    } catch (error) {
      dispatch({
        type: FETCH_QUIZ_TOPICS_FAILURE,
        payload: error.message,
      });
    } finally {
      dispatch({ type: TOGGLE_LOADING, payload: false });
    }
  };
};
