import {
  TOGGLE_LOADING,
  FETCH_GAMES_SUCCESS,
  ADD_GAMES_SUCCESS,
  FETCH_GAME_DETAILS_SUCCESS,
  TOGGLE_GAME_ACTIVE_STATUS,
  UPDATE_GAME_REQUEST,
  RESET_GAMES_DATA,
} from "../types";

const initialState = {
  gamelist: [],
  hasMore: true,
  loading: false,
  error: null,
  gameDetails: null,
  pageNumber: 1,
  pageSize: 20,
};

const gamesReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_LOADING:
      return { ...state, loading: action.payload };

    case FETCH_GAMES_SUCCESS:
      return {
        ...state,
        gamelist: [...state.gamelist, ...action.payload.data],
        hasMore: action.payload.hasMore,
        error: null,
      };
    case ADD_GAMES_SUCCESS:
      return {
        ...state,
        error: null,
      };
    case TOGGLE_GAME_ACTIVE_STATUS:
      console.log("Action:", action); // Logging the entire action object
      console.log("Payload:", action.payload); // Logging just the payload for clarity
      return {
        ...state,
        loading: false,
        gamelist: state.gamelist.map((game) =>
          game._id === action.payload.gameId
            ? { ...game, is_active: action.payload.isActive }
            : game
        ),
      };

    case UPDATE_GAME_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case FETCH_GAME_DETAILS_SUCCESS:
      return {
        ...state,
        gameDetails: action.payload,
        error: null,
      };

    case RESET_GAMES_DATA:
      return initialState;

    default:
      return state;
  }
};

export default gamesReducer;
