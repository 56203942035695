// quizReducer.js
import { TOGGLE_LOADING, GENERATE_QUIZ_SUCCESS, FETCH_QUIZ_SUCCESS, FETCH_QUIZ_FAILURE, RESET_QUIZ_DATA, DELETE_QUIZ_SUCCESS, UPDATE_QUIZ_SUCCESS } from "../types";

const initialState = {
  quiz: [], 
  hasMore: true,
  loading: false, 
  error: null, 
  pageNumber: 1,
  pageSize: 20,
};

const quizReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    case GENERATE_QUIZ_SUCCESS:
      return {
        ...state,
        quiz: action.payload,
        loading: false,
      };

    case FETCH_QUIZ_SUCCESS:
      return {
        ...state,
        quiz: [...state.quiz, ...action.payload.data],
        hasMore: action.payload.hasMore,
        loading: false,
        error: null,
      };
    case FETCH_QUIZ_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

      case DELETE_QUIZ_SUCCESS:
        console.log("DELETE_QUIZ_SUCCESS payload:", action.payload);
        return {
          ...state,
          loading: false,
          quiz: state.quiz.filter((question) => question._id !== action.payload),
        };

        case UPDATE_QUIZ_SUCCESS:
      console.log("UPDATE_QUIZ_SUCCESS payload:", action.payload); // Ensure payload is correct
      return {
        ...state,
        loading: false,
        quiz: state.quiz.map((question) =>
          question._id === action.payload._id // Assuming payload directly contains updated question
            ? { ...question, ...action.payload }
            : question
        ),
        message: action.payload.message, // Optional, ensure this is used where needed
      };
        
      


    case RESET_QUIZ_DATA:
      return initialState;

    default:
      return state;
  }
};

export default quizReducer;
