import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Box,
  Grid,
  Card,
  Typography,
  CircularProgress,
  MenuItem,
  TextField,
} from "@material-ui/core";
import CustomHeader from "components/Headers/CustomHeader";
import componentStyles from "assets/theme/views/admin/tables.js";
import { fetchAllAnalytics } from "store/actions/AnalyticAction";
import { fetchAllCampaigns } from "store/actions/campaignAction";
import { fetchAllUsers } from "store/actions/userAction";

const useStyles = makeStyles(componentStyles);

const Analytics = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [filters, setFilters] = useState({
    start_date: "",
    end_date: "",
    campaign_id: "",
    user_id: "",
    type: "",
    page_number: 1,
    page_size: 10,
  });

  const analyticsData = useSelector((state) => state.analytics.analyticsList);
  const loading = useSelector((state) => state.analytics.loading);
  const error = useSelector((state) => state.analytics.error);

  const campaignsData = useSelector((state) => state.campaign.data);
  const campaignLoading = useSelector((state) => state.campaign.loading);
  const campaignError = useSelector((state) => state.campaign.error);

  const {
    list: userData,
    loading: userLoading,
    error: userError,
  } = useSelector(({ users }) => ({
    list: users.list,
    loading: users.loading,
    error: users.error,
  }));

  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    dispatch(fetchAllAnalytics());
    dispatch(fetchAllUsers());
  }, [dispatch]);

  useEffect(() => {
    const fetchCampaigns = () => {
      const fetchVariables = {
        page_number: 1,
        page_size: 20,
      };
      dispatch(fetchAllCampaigns(fetchVariables));
    };
    fetchCampaigns();
  }, [dispatch]);

  useEffect(() => {
    let data = [...analyticsData];

    if (filters.start_date && filters.end_date) {
      const startDate = new Date(filters.start_date);
      const endDate = new Date(filters.end_date);
      data = data.filter((item) => {
        const itemDate = new Date(item.date);
        return itemDate >= startDate && itemDate <= endDate;
      });
    }

    if (filters.campaign_id) {
      data = data.filter((item) => item.campaign_id === filters.campaign_id);
    }

    if (filters.user_id) {
      data = data.filter((item) => item.user_id === filters.user_id);
    }

    if (filters.type) {
      data = data.filter((item) => item.type === filters.type);
    }

    setFilteredData(data);
  }, [filters, analyticsData]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value || "",
    }));
  };

 
  const uniqueTypes = Array.from(
    new Set(analyticsData.map((analytic) => analytic.type))
  );

  return (
    <>
      <CustomHeader />
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
        {/* Filters Section */}
        <Box
          padding={3}
          bgcolor="grey.200"
          mb={4}
          position="relative" // Ensure positioning is relative
          zIndex={1200} // Higher z-index to overlap header
          top={-20} // Adjust top positioning if needed
        >
          <Grid container spacing={3}>
            {/* Start Date Picker */}
            <Grid item xs={12} md={3}>
              <TextField
                label="Start Date"
                type="date"
                variant="outlined"
                fullWidth
                name="start_date"
                InputLabelProps={{
                  shrink: true,
                  style: { paddingBottom: "8px" }, // Adjust as needed
                }}
                InputProps={{
                  style: { paddingTop: "8px" }, // Adjust as needed
                }}
                value={filters.start_date}
                onChange={handleFilterChange}
              />
            </Grid>

            {/* End Date Picker */}
            <Grid item xs={12} md={3}>
              <TextField
                label="End Date"
                type="date"
                variant="outlined"
                fullWidth
                name="end_date"
                InputLabelProps={{
                  shrink: true,
                  style: { paddingBottom: "8px" }, // Adjust as needed
                }}
                InputProps={{
                  style: { paddingTop: "8px" }, // Adjust as needed
                }}
                value={filters.end_date}
                onChange={handleFilterChange}
              />
            </Grid>
            {/* Campaign Dropdown */}
            <Grid item xs={12} md={3}>
              <TextField
                label="Campaign"
                variant="outlined"
                fullWidth
                name="campaign_id"
                select
                value={filters.campaign_id}
                onChange={handleFilterChange}
                InputLabelProps={{
                  shrink: true,
                  style: { paddingBottom: "8px" }, // Adjust as needed
                }}
                InputProps={{
                  style: { paddingTop: "8px" }, // Adjust as needed
                }}
              >
                {campaignLoading ? (
                  <MenuItem disabled>Loading campaigns...</MenuItem>
                ) : campaignError ? (
                  <MenuItem disabled>Error loading campaigns</MenuItem>
                ) : campaignsData.length > 0 ? (
                  campaignsData.map((campaign) => (
                    <MenuItem key={campaign._id} value={campaign._id}>
                      {campaign.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No campaigns available</MenuItem>
                )}
              </TextField>
            </Grid>

            {/* User Dropdown */}
            <Grid item xs={12} md={3}>
              <TextField
                label="User"
                variant="outlined"
                fullWidth
                name="user_id"
                select
                value={filters.user_id}
                onChange={handleFilterChange}
                InputLabelProps={{
                  shrink: true,
                  style: { paddingBottom: "8px" }, // Adjust as needed
                }}
                InputProps={{
                  style: { paddingTop: "8px" }, // Adjust as needed
                }}
              >
                {userData.length > 0 ? (
                  userData.map((user) => (
                    <MenuItem key={user._id} value={user._id}>
                      {user.email}
                    </MenuItem>
                  ))
                ) : userLoading ? (
                  <CircularProgress />
                ) : userError ? (
                  <MenuItem disabled>Error loading users</MenuItem>
                ) : (
                  <MenuItem disabled>No users available</MenuItem>
                )}
              </TextField>
            </Grid>

            {/* Type Dropdown */}
            <Grid item xs={12} md={3}>
              <TextField
                label="Type"
                variant="outlined"
                fullWidth
                name="type"
                select
                value={filters.type}
                onChange={handleFilterChange}
                InputLabelProps={{
                  shrink: true,
                  style: { paddingBottom: "8px" }, // Adjust as needed
                }}
                InputProps={{
                  style: { paddingTop: "8px" }, // Adjust as needed
                }}
              >
                {uniqueTypes.length > 0 ? (
                  uniqueTypes.map((type, index) => (
                    <MenuItem key={index} value={type}>
                      {type}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No types available</MenuItem>
                )}
              </TextField>
            </Grid>
          </Grid>
        </Box>

        {/* Data Display Section */}
        <Grid container spacing={3}>
          {loading ? (
            <CircularProgress style={{ margin: "auto" }} />
          ) : error ? (
            <Typography variant="h6" color="error">
              {error}
            </Typography>
          ) : filteredData.length === 0 ? (
            <Typography variant="h3">No analytics data available.</Typography>
          ) : (
            filteredData.map((analytic, index) => (
              <Grid item xs={12} md={12} key={index}>
                <Card style={{ padding: "1em" }}>
                  <Typography variant="h4">
                    IP Address: {analytic.ip_address}
                  </Typography>
                  <Typography variant="body1">
                    Location: {analytic.location}
                  </Typography>
                  <Typography variant="body1">
                    Browser: {analytic.browser}
                  </Typography>
                  <Typography variant="body2">
                    Date and Time:{" "}
                    {new Date(analytic.timestamp * 1000).toLocaleString()}
                  </Typography>
                  <Typography variant="body2">
                    Device Type: {analytic.device_type}
                  </Typography>
                  <Typography variant="body2">
                    Device Vendor: {analytic.device_vendor}
                  </Typography>
                  <Typography variant="body2">
                    Device Model: {analytic.device_model}
                  </Typography>
                  <Typography variant="body2">Type: {analytic.type}</Typography>
                </Card>
              </Grid>
            ))
          )}
        </Grid>
      </Container>
    </>
  );
};

export default Analytics;
