export const LIST_ALL_WALLETS = `query  {
  list_all_wallets {
    data {
      _id
      is_linked
      network
      amount
      wallet_address
      user_id {
        _id
        name
        email
      }
    }
    
  }
}`;

export const FETCH_WALLETS_FOR_DROPDOWN = `query{
    list_wallets_for_dropdown{
      message
      data {
        _id
        wallet_id
        is_linked
      }
    }
  }`;

  
  export const LIST_LEDGER_BALANCES = `
  query ($balance: GetLedgerBalanceDto!) {
    list_ledger_balances(balance: $balance) {
      data {
        wallet_address
        network
        balance
      }
    }
  }
`;
