import {
  TOGGLE_LOADING,
  FETCH_USERS_SUCCESS,
  SEND_TOKENS_SUCCESS
} from "../types";
import { post } from "services";
import { LIST_ALL_USERS } from "graphql/user";
import { SEND_TOKENS_TO_EMAIL } from "graphql/user"; // Import GraphQL mutation here
import { SEND_TOKENS_TO_USER } from "graphql/user";

// Action creator to fetch all users
export const fetchAllUsers = () => {
  return async (dispatch) => {
    dispatch({ type: TOGGLE_LOADING, payload: true });

    try {
      const apiResponse = await post("", {
        query: LIST_ALL_USERS,
        variables: {},
      });

      if (apiResponse.data && apiResponse.data.data.list_all_users) {
        const { data } = apiResponse.data.data.list_all_users;

        dispatch({
          type: FETCH_USERS_SUCCESS,
          payload: data,
        });
      } 
    } finally {
      dispatch({ type: TOGGLE_LOADING, payload: false });
    }
  };
};


export const sendTokensToEmail = (sendTokensDto) => {
  return async (dispatch) => {
    dispatch({ type: TOGGLE_LOADING, payload: true });

    try {
      const apiResponse = await post("/graphql", {
        query: SEND_TOKENS_TO_EMAIL,
        variables: {
          send_tokens: sendTokensDto,
        },
      });
      console.log("API Response:", apiResponse);

      if (apiResponse.data && apiResponse.data.data.send_tokens_to_email) {
        const message = apiResponse.data.data.send_tokens_to_email.message;
        dispatch({
          type: SEND_TOKENS_SUCCESS,
          payload: message,
        });

        // Optionally, you can dispatch another action for success if required
      } else {
        throw new Error("Failed to send tokens"); // Throw an error if response doesn't match expected format
      }
    } catch (error) {
      console.error("Error sending tokens:", error);
      
    } finally {
      dispatch({ type: TOGGLE_LOADING, payload: false });
    }
  };
};

export const sendTokensToUser = (sendTokensDoto) => {
  return async (dispatch) => {
    dispatch({ type: TOGGLE_LOADING, payload: true });

    try {
      const apiResponse = await post("", {
        query: SEND_TOKENS_TO_USER,
        variables: {
          send_tokens: sendTokensDoto,
        },
      });
      console.log("API Response:", apiResponse);

      if (apiResponse.data && apiResponse.data.data.send_tokens_to_user) {
        const message1 = apiResponse.data.data.send_tokens_to_user.message;
        
        dispatch({
          type: SEND_TOKENS_SUCCESS,
          payload: message1,
        });

        // Optionally, you can dispatch another action for success if required
      } else {
        throw new Error("Failed to send tokens"); // Throw an error if response doesn't match expected format
      }
    } catch (error) {
      console.error("Error sending tokens:", error);
      // Optionally, dispatch a failure action if needed
      // dispatch({ type: SEND_TOKENS_FAILURE, payload: error.message });
    } finally {
      dispatch({ type: TOGGLE_LOADING, payload: false });
    }
  };
};