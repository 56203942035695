import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Box } from "@material-ui/core";

import CustomHeader from "components/Headers/CustomHeader";
import CustomTable from "components/CustomTable/CustomTable";
import { activateReward, createNewReward, fetchAllRewards } from "store/actions/rewardaction";
import componentStyles from "assets/theme/components/navbar-dropdown";
import CustomIconButton from "components/CustomButton/CustomIconButton";
import { Card, Grid } from "../../../../node_modules/@material-ui/core/index";
import { useState } from "react";
import { toggleSidebar } from "store/actions/sidebarActions";
import {
  generateInputs,
  initialValues,
  validationSchema,
} from "./create_reward";
import CustomSidebar from "components/CustomSidebar/CustomSidebar";
import { PlusCircle } from "../../../../node_modules/react-feather/dist/index";
import { Switch } from "../../../../node_modules/@material-ui/core/index";


const useStyles = makeStyles(componentStyles);

function Rewards() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [sidebarInputs, setSidebarInputs] = useState([]);
  const [refreshPage, setRefreshPage] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const {
    reward: rewardsData,
    loading,
    error,
  } = useSelector(({ reward }) => ({
    reward: reward.data,
    loading: reward.loading,
    error: reward.error,
  }));

  useEffect(() => {
    const fetchVariables = {
      page_number: 1,
      page_size: 20,
      is_for_dropdown: false,
    };
    dispatch(fetchAllRewards(fetchVariables));
  }, [dispatch, refreshPage]);

  const handlereward = () => {
    setSidebarInputs(generateInputs());
    dispatch(toggleSidebar(true));
  };

  const handleSubmit = (values) => {
    const { name, description, count } = values;

    dispatch(createNewReward({ name, description, count }))
      .then(() => {
        // Refresh page or update state as needed
        setRefreshPage((prev) => !prev); // Assuming this is used to refresh data
        dispatch(toggleSidebar(false)); // Close the sidebar after submission
        setLoadingSubmit(false); // Stop loading spinner
      })
      .catch((error) => {
        console.error("Error creating reward:", error); // Log error for debugging
        setLoadingSubmit(false); // Stop loading spinner
      });
  };

  const handleSidebarClose = () => {
    dispatch(toggleSidebar(false)); // Close the sidebar
  };

  const handleToggleActiveStatus = (rewardId, isActive) => {
    dispatch(activateReward (rewardId, isActive))
      .then(() => {
        setRefreshPage((prev) => !prev);
      })
      .catch((error) => {
        console.error("Error toggling reward status:", error);
      });
  };

  return (
    <>
      <CustomHeader />
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
        <Grid container justify="flex-start" spacing={3}>
          <Grid item style={{ width: "100%" }}>
            <Card style={{ backgroundColor: "grey" }}>
              <Grid container spacing={3}>
                <Grid item style={{ padding: "1.5em" }}>
                  <CustomIconButton
                    title="Create Reward"
                    icon={<PlusCircle />}
                    onClick={handlereward}
                    style={{ width: "100%" }}
                  />
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
        <br />
        {loading && <p>Loading...</p>}
        {error && <p>Error: {error}</p>}
        <CustomTable
          title="Rewards"
          columns={[
            { title: "Name", field: "name" },
            { title: "Description", field: "description" },
            {
              title: "Active",
              field: "is_active",
              render: (rowData) => (
                <Switch
                  checked={rowData.is_active}
                  onChange={() =>
                    handleToggleActiveStatus(rowData._id, !rowData.is_active)
                  }
                  color="primary"
                  // disabled={
                  //   isSameRewardCodeActive(rowData.reward_code) &&
                  //   !rowData.is_active
                  // }
                  inputProps={{ "aria-label": "Toggle Active Status" }}
                />
              ),
            },
            { title: "Total Count", field: "total_count" },
            { title: "Remaining Count", field: "remaining_count" },
          ]}
          data={rewardsData}
        />
      </Container>
      <CustomSidebar
        title="Create Reward"
        inputs={sidebarInputs}
        validationSchema={validationSchema}
        initialValues={initialValues}
        handleSubmit={handleSubmit}
        handleClose={handleSidebarClose}
      />
    </>
  );
}

export default Rewards;
