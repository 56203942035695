export const GET_ALL_QUIZ_TOPICS = `
  query {
    list_all_quiz_topics(quiz: {}) {
      data {
        _id
        name
        is_active
      }
      hasMore
    }
  }
`;
