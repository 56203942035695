import { LIST_ALL_ANALYTICS } from "graphql/Analytics";
import { FETCH_ANALYTICS_SUCCESS,TOGGLE_LOADING, } from "store/types";
import { post } from "services";

export const fetchAllAnalytics = (filters) => {
  return async (dispatch) => {
    dispatch({ type: TOGGLE_LOADING, payload: true });

    try {
      const apiResponse = await post("", {
        query: LIST_ALL_ANALYTICS,
        variables: {
          analytics: {
            ...filters, 
          }
        },
      });

      if (apiResponse.data && apiResponse.data.data.list_all_analytics) {
        const { data } = apiResponse.data.data.list_all_analytics;

        console.log(data)

        dispatch({
          type: FETCH_ANALYTICS_SUCCESS,
          payload: data,
        });
      }
    } catch (error) {
      console.error("Error fetching analytics:", error);
    } finally {
      dispatch({ type: TOGGLE_LOADING, payload: false });
    }
  };
};