import * as Yup from "yup";

// Function to generate form inputs
export const generateInputs = () => {
  const inputs = [
    {
      name: "topic",
      label: "Topic",
      type: "text",
      placeHolder: "Enter topic",
      visibility: true,
    },
  ];

  return inputs;
};

// Validation schema using Yup
export const validationSchema = Yup.object().shape({
  topic: Yup.string().required("Topic is required"), // Validate topic
});

// Initial values for the form
export const initialValues = {
  topic: "", // Initial value for topic
};
