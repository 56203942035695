import {
  TOGGLE_LOADING,
  FETCH_GAMES_SUCCESS,
  ADD_GAMES_SUCCESS,
  FETCH_GAME_DETAILS_SUCCESS,
  TOGGLE_GAME_ACTIVE_STATUS,
  UPDATE_GAME_REQUEST,
  UPDATE_GAME_SUCCESS,
  RESET_GAMES_DATA,
} from "../types";
import { post } from "services";

import {
  ACTIVATE_GAME,
  ADD_GAME,
  GAME_DETAILS,
  LIST_ALL_GAMES,
  UPDATE_GAME,
} from "graphql/game";

// Action creator to fetch all users
export const fetchAllgames = (page_number, page_size) => {
  return async (dispatch) => {
    dispatch({ type: TOGGLE_LOADING, payload: true });

    try {
      const apiResponse = await post("", {
        query: LIST_ALL_GAMES,
        variables: {
          game: {
            page_number,
            page_size,
          },
        },
      });

      if (apiResponse.data && apiResponse.data.data.list_all_games) {
        const { data, hasMore } = apiResponse.data.data.list_all_games;
        console.log("Fetched Games Data:", data);

        dispatch({
          type: FETCH_GAMES_SUCCESS,
          payload: { data, hasMore },
        });
      }
    } catch (error) {
      console.error("Error fetching all games:", error);
    } finally {
      dispatch({ type: TOGGLE_LOADING, payload: false });
    }
  };
};

export const addgame = (games) => {
  return async (dispatch) => {
    dispatch({ type: TOGGLE_LOADING, payload: true });

    try {
      const apiResponse = await post("/graphql", {
        query: ADD_GAME,
        variables: {
          game: games,
        },
      });
      console.log("API Response:", apiResponse);

      if (apiResponse.data && apiResponse.data.data.add_new_game) {
        const message = apiResponse.data.data.add_new_game.message;
        dispatch({
          type: ADD_GAMES_SUCCESS,
          payload: message,
        });
      } else {
        throw new Error("Failed to ADD GAME");
      }
    } catch (error) {
      console.error("Error adding game:", error);
    } finally {
      dispatch({ type: TOGGLE_LOADING, payload: false });
    }
  };
};

export const fetchGameDetails = (gameId) => {
  return async (dispatch) => {
    dispatch({ type: TOGGLE_LOADING, payload: true });

    try {
      const apiResponse = await post("", {
        query: GAME_DETAILS,
        variables: { gameId },
      });

      if (apiResponse.data && apiResponse.data.data.game_details) {
        const data = apiResponse.data.data.game_details?.data;
        console.log("data", data);

        dispatch({
          type: FETCH_GAME_DETAILS_SUCCESS,
          payload: data,
        });
      } else {
        throw new Error("Failed to fetch game details");
      }
    } catch (error) {
      console.error("Error fetching game details:", error);
    } finally {
      dispatch({ type: TOGGLE_LOADING, payload: false });
    }
  };
};

export const updategame = (game) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_GAME_REQUEST });

    try {
      const apiResponse = await post("/graphql", {
        query: UPDATE_GAME,
        variables: { game },
      });

      console.log("API Response:", apiResponse);

      if (apiResponse.data && apiResponse.data.data.update_game) {
        const message = apiResponse.data.data.update_game?.message;
        dispatch({
          type: UPDATE_GAME_SUCCESS,
          payload: message,
        });
      } else {
        throw new Error("Failed to update game");
      }
    } catch (error) {
      console.error("Error updating game:", error);
    } finally {
      dispatch({ type: TOGGLE_LOADING, payload: false });
    }
  };
};

export const toggleGameActiveStatus = (gameId, isActive) => {
  return async (dispatch) => {
    dispatch({
      type: TOGGLE_GAME_ACTIVE_STATUS,
      payload: { gameId, isActive },
    });

    try {
      const apiResponse = await post("/graphql", {
        query: ACTIVATE_GAME,
        variables: { game: { _id: gameId } },
      });

      console.log("API Response:", apiResponse);

      if (apiResponse.data && apiResponse.data.data.activate_game) {
        const message = apiResponse.data.data.activate_game.message;
        dispatch({
          type: TOGGLE_GAME_ACTIVE_STATUS,
          payload: { gameId, isActive, message }, // Ensure payload structure is correct
        });
      } else {
        throw new Error("Failed to toggle game active status");
      }
    } catch (error) {
      console.error("Error toggling game active status:", error);
    }
  };
};

export const resetgameData = () => {
  return async (dispatch) => {
    dispatch({
      type: RESET_GAMES_DATA,
    });
  };
};
