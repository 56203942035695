import {
  TOGGLE_LOADING,
  FETCH_USERS_SUCCESS,
  FETCH_LEDGER_SUCCESS,
} from "../types";
import { post } from "services";
import { LIST_ALL_WALLETS, LIST_LEDGER_BALANCES } from "graphql/wallets";

export const fetchAllwallets = () => {
  return async (dispatch) => {
    dispatch({ type: TOGGLE_LOADING, payload: true });

    try {
      const apiResponse = await post("", {
        query: LIST_ALL_WALLETS,
        variables: {},
      });

      if (apiResponse.data && apiResponse.data.data.list_all_wallets) {
        const { data } = apiResponse.data.data.list_all_wallets;

        dispatch({
          type: FETCH_USERS_SUCCESS,
          payload: data,
        });
      }
    } catch (error) {
    } finally {
      dispatch({ type: TOGGLE_LOADING, payload: false });
    }
  };
};

export const fetchAllledger = () => {
  return async (dispatch) => {
    dispatch({ type: TOGGLE_LOADING, payload: true });

    try {
      const balanceData = {};
      const apiResponse = await post("", {
        query: LIST_LEDGER_BALANCES,
        variables: { balance: balanceData },
      });

      if (apiResponse.data && apiResponse.data.data.list_ledger_balances) {
        const { data } = apiResponse.data.data.list_ledger_balances;

        dispatch({
          type: FETCH_LEDGER_SUCCESS,
          payload: data,
        });
      }
    } catch (error) {
    } finally {
      dispatch({ type: TOGGLE_LOADING, payload: false });
    }
  };
};
