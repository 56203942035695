export const LIST_ALL_REWARDS = `
  query list_all_rewards($reward: GetAllRewardsDto!) {
    list_all_rewards(reward: $reward) {
      data {
        _id
        name
        description
        is_active
        total_count
        remaining_count
      }
      hasMore
    }
  }
`;

export const CREATE_NEW_REWARD = `
  mutation create_new_reward($reward: CreateRewardDto!) {
    create_new_reward(reward: $reward) {
      message
    }
  }
`;

export const ACTIVATE_REWARD = `
  mutation ActivateReward($reward: IdOnlyDto!) {
    activate_reward(reward: $reward) {
      message
    }
  }
`;



