import React, { useCallback, useMemo, useState } from "react";
import {
  Backdrop,
  CircularProgress,
  TextField,
  MenuItem,
  Button,
  Grid,
  makeStyles,
  Card,
  CardContent,
  CardActions,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";

import { useDispatch, useSelector } from "react-redux";
import { addgame } from "store/actions/gameAction";
import CustomTextButton from "components/CustomButton/CustomTextButton";
import { useEffect } from "react";
import { fetchQuizTopics } from "store/actions/topicAction";
import { fetchAllRewards } from "store/actions/rewardaction";

const gameCodes = ["quiz", "spin_wheel"];
const tokenNetworks = ["funq", "solana"];
const INITIAL_NETWORKS = ["funq", "solana"];

const useStyles = makeStyles((theme) => ({
  modalContent: {
    maxHeight: "100vh",
    overflowY: "auto",
    width: "80%",
    padding: theme.spacing(20),
    margin: "auto",
    backgroundColor: "white",
    borderRadius: theme.shape.borderRadius,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  questionCard: {
    marginBottom: theme.spacing(2),
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  addQuestionButton: {
    marginTop: theme.spacing(2),
  },
}));

const AddGameModal = ({ campaignId, onClose, onGameAdded }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [gameName, setGameName] = useState("");
  const [gameCode, setGameCode] = useState("quiz");
  const [tokenAmount, setTokenAmount] = useState(0);
  const [tokenNetwork, setTokenNetwork] = useState("funq");
  const [totalCount, setTotalCount] = useState(0);
  const [includeai, setIncludeai] = useState(true);
  const [gameLevels, setGameLevels] = useState([
    {
      level: 1,
      tokens_required: 0,
      token_network: "funq",
      price_amount: 0,
      price_network: "funq",
      spin_time: 0,
      total_questions: 10,
      topics: [],
      prices: [{ network: "", amount: 0, type: "", id: "" }],
      questions: [
        {
          question: "",
          options: [],
          answer: "",
          answer_time: 0,
        },
      ],
    },
  ]);
  const [currentLevelIndex, setCurrentLevelIndex] = useState(0);
  const [selectedTokenNetworks, setSelectedTokenNetworks] = useState(
    gameLevels.map((level) => level.token_network)
  );
  const reward = useSelector((state) => state.reward.data);

  const [priceNetworks, setPriceNetworks] = useState([]);

  const [selectedPriceNetworks, setSelectedPriceNetworks] = useState(
    gameLevels.flatMap((level) => level.prices.map((price) => price.network))
  );

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const { topic } = useSelector((state) => state.topic);

  useEffect(() => {
    if (!reward) {
      const variables = {
        page_number: 1,
        page_size: 100,
        is_for_dropdown: true,
      };
      dispatch(fetchAllRewards(variables));
    }
  }, [dispatch, reward]);

  useEffect(() => {
    if (reward && reward.length > 0) {
      const rewardNetworks = reward.map((reward) => reward.name);
      setPriceNetworks((prevNetworks) => [
        ...new Set([
          ...prevNetworks,
          ...INITIAL_NETWORKS /*...rewardNetworks*/,
        ]),
      ]);
    }
  }, [reward]);

  useEffect(() => {
    if (topic.length === 0) {
      dispatch(fetchQuizTopics());
    }
  }, [dispatch, topic]);

  const validateButton = () => {
    if (loading) {
      // If loading, button should be disabled
      setIsButtonDisabled(true);
    } else {
      if (gameCode === "spin_wheel") {
        // Always enable the button for spin_wheel
        setIsButtonDisabled(false);
      } else {
        // For other game codes (like "quiz")
        if (includeai) {
          // If include_ai_questions is true, enable the button
          setIsButtonDisabled(false);
        } else {
          // If include_ai_questions is false, enable the button only if there are questions
          const hasQuestions = gameLevels.some(
            (level) => level.questions && level.questions.length > 0
          );
          setIsButtonDisabled(!hasQuestions);
        }
      }
    }
  };

  const handleTopicsChange = (levelIndex, event) => {
    const { value } = event.target; // Extract selected values
    setGameLevels((prevLevels) => {
      const newLevels = [...prevLevels];
      newLevels[levelIndex].topics = value; // Set selected topics
      return newLevels;
    });
  };

  // useEffect to update button state
  useEffect(() => {
    validateButton();
  }, [loading, includeai, gameLevels, gameCode]);

  const handleTokenNetworkChange = (levelIndex, value) => {
    // Update gameLevels directly
    const newGameLevels = [...gameLevels];
    newGameLevels[levelIndex].token_network = value;
    setGameLevels(newGameLevels);

    // Update selectedTokenNetworks
    const newSelectedTokenNetworks = [...selectedTokenNetworks];
    newSelectedTokenNetworks[levelIndex] = value;
    setSelectedTokenNetworks(newSelectedTokenNetworks);
  };

  const handlePriceNetworkChangeForQuiz = (levelIndex, value) => {
    const newGameLevels = [...gameLevels];
    newGameLevels[levelIndex].price_network = value; // Directly update price_network
    setGameLevels(newGameLevels);
  };

  const handlePriceNetworkChange = (levelIndex, priceIndex, value) => {
    // Make a shallow copy of game levels
    const newGameLevels = [...gameLevels];

    // Ensure that the prices array exists for the level
    if (!newGameLevels[levelIndex].prices) {
      newGameLevels[levelIndex].prices = [];
    }

    // Check if the price object exists, otherwise create it
    if (!newGameLevels[levelIndex].prices[priceIndex]) {
      newGameLevels[levelIndex].prices[priceIndex] = {
        network: "",
        amount: 0,
        type: "",
        id: null,
      };
    }

    if (INITIAL_NETWORKS.includes(value)) {
      // For initial networks
      newGameLevels[levelIndex].prices[priceIndex].network = value;
      newGameLevels[levelIndex].prices[priceIndex].id = null; // No ID for initial networks
      newGameLevels[levelIndex].prices[priceIndex].amount = 0; // Set default amount
      newGameLevels[levelIndex].prices[priceIndex].type = "token"; // Set default type
    } else {
      // For fetched rewards
      const selectedReward = reward.find((r) => r.name === value);
      if (selectedReward) {
        newGameLevels[levelIndex].prices[priceIndex].network = ""; // Network is empty for fetched rewards
        newGameLevels[levelIndex].prices[priceIndex].id = selectedReward._id; // ID for the fetched reward
        newGameLevels[levelIndex].prices[priceIndex].amount =
          parseFloat(selectedReward.amount) || 0;
        newGameLevels[levelIndex].prices[priceIndex].type = "reward"; // Set type for fetched rewards
      }
    }

    // Update the game levels state
    setGameLevels(newGameLevels);

    // Update the selected price networks state
    const newSelectedPriceNetworks = [...selectedPriceNetworks];
    newSelectedPriceNetworks[
      levelIndex * gameLevels[0].prices.length + priceIndex
    ] = value;
    setSelectedPriceNetworks(newSelectedPriceNetworks);
  };

  const validateQuestion = (question) => {
    const { question: q, options, answer } = question;
    return q && options.length > 0 && options.every((opt) => opt) && answer;
  };

  const validatePrice = (price) => {
    const { network, amount, type, id } = price;

    // Check if the price is valid for both initial networks and fetched rewards
    const isValid =
      (network !== "" || id !== null) && amount > 0 && type !== "";

    console.log("Validating price:", price, "->", isValid); // Debug log
    return isValid;
  };

  const isAddLevelDisabled = gameLevels.some((level) => {
    // Common fields
    const commonFieldsEmpty =
      !level.level || !level.tokens_required || !level.token_network;

    // Additional fields for quiz
    const quizFieldsEmpty =
      gameCode === "quiz" &&
      (!level.price_amount ||
        !level.price_network ||
        !level.total_questions ||
        level.questions.some(
          (q) =>
            !q.question ||
            q.options.some((o) => !o) ||
            !q.answer ||
            !q.answer_time
        ));

    // Additional fields for spin_wheel
    const spinWheelFieldsEmpty = gameCode === "spin_wheel" && !level.spin_time;

    return commonFieldsEmpty || quizFieldsEmpty || spinWheelFieldsEmpty;
  });

  console.log("isAddLevelDisabled:", isAddLevelDisabled); // Debugging line

  const validateAddQuestion = useCallback(() => {
    const currentLevel = gameLevels[currentLevelIndex];
    return currentLevel.questions.every(validateQuestion);
  }, [gameLevels, currentLevelIndex]);

  const useValidateAddPrice = (gameLevels, currentLevelIndex) => {
    const validateAddPrice = useCallback(() => {
      const currentLevel = gameLevels[currentLevelIndex];
      if (!currentLevel || !currentLevel.prices) return false;
      const isValid = currentLevel.prices.every(validatePrice);
      console.log("Validating add price button:", isValid); // Debug log
      return isValid;
    }, [gameLevels, currentLevelIndex]);

    return useMemo(() => !validateAddPrice(), [validateAddPrice]);
  };

  const isAddQuestionDisabled = !validateAddQuestion();

  const handleAddLevel = () => {
    if (!isAddLevelDisabled) {
      setGameLevels((prevLevels) => [
        ...prevLevels,
        {
          level: prevLevels.length + 1,
          tokens_required: 0,
          token_network: "",
          price_amount: 0,
          price_network: "",
          spin_time: 0,
          topics: [],
          total_questions: 0,
          prices: [{ network: "", amount: 0, type: "", id: "" }],
          questions: [
            {
              question: "",
              options: [],
              answer: "",
              answer_time: 0,
            },
          ],
        },
      ]);

      setCurrentLevelIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handleLevelChange = (levelIndex, field, value) => {
    setGameLevels((prevLevels) => {
      const newLevels = [...prevLevels];
      if (field === "options") {
        const { optionIndex, newValue } = value;
        newLevels[levelIndex].questions[0].options[optionIndex] = newValue;
      } else if (field === "prices") {
        const { priceIndex, field: priceField, value: priceValue } = value;
        newLevels[levelIndex].prices[priceIndex][priceField] = priceValue;
      } else if (field.startsWith("question")) {
        const parts = field.split("_");
        const questionIndex = parseInt(parts[1]);
        const questionField = parts.slice(2).join("_");
        newLevels[levelIndex].questions[questionIndex][questionField] = value;
      } else {
        newLevels[levelIndex][field] = value;
      }
      return newLevels;
    });
  };

  const isAddOptionEnabled = (levelIndex, questionIndex) => {
    const options =
      gameLevels[levelIndex]?.questions[questionIndex]?.options || [];
    const lastOption = options[options.length - 1];

    return (
      options.length === 0 ||
      (typeof lastOption === "string" && lastOption.trim() !== "")
    );
  };

  const handleAddOption = (levelIndex, questionIndex) => {
    if (isAddOptionEnabled(levelIndex, questionIndex)) {
      setGameLevels((prevLevels) => {
        const newLevels = [...prevLevels];
        newLevels[levelIndex]?.questions[questionIndex]?.options.push("");
        return newLevels;
      });
    }
  };

  const handleRemoveOption = (levelIndex, questionIndex, optionIndex) => {
    setGameLevels((prevLevels) => {
      const newLevels = [...prevLevels];

      const question = newLevels[levelIndex]?.questions[questionIndex];

      if (question && question.options) {
        question.options.splice(optionIndex, 1);

        if (question.options.length === 0) {
        }
      }

      return newLevels;
    });
  };

  const isAddPriceDisabled = useValidateAddPrice(gameLevels, currentLevelIndex);

  const handleAddPrice = (levelIndex) => {
    console.log("Add Price clicked, isAddPriceDisabled:", isAddPriceDisabled); // Debug log
    if (!isAddPriceDisabled) {
      const newLevels = [...gameLevels];
      newLevels[levelIndex].prices.push({ network: "", amount: 0, type: "" });
      setGameLevels(newLevels);
    }
  };

  const handleRemovePrice = (levelIndex, priceIndex) => {
    const newLevels = [...gameLevels];
    newLevels[levelIndex].prices.splice(priceIndex, 1);
    setGameLevels(newLevels);
  };

  const handleAddQuestion = (levelIndex) => {
    if (!isAddQuestionDisabled) {
      const newLevels = [...gameLevels];
      newLevels[levelIndex].questions.push({
        question: "",
        options: [],
        answer: "",
        answer_time: 0,
      });
      setGameLevels(newLevels);
    }
  };

  const handleRemoveQuestion = (levelIndex, questionIndex) => {
    const newLevels = [...gameLevels];
    newLevels[levelIndex].questions.splice(questionIndex, 1);
    setGameLevels(newLevels);
  };

  const handleSubmit = async () => {
    setLoading(true);

    const newGame = {
      name: gameName,
      game_code: gameCode,
      token_amount: parseFloat(tokenAmount),
      token_network: tokenNetwork,
      total_count: parseInt(totalCount),
      include_ai_questions: includeai,
      campaign_id: campaignId,
      game_levels: gameLevels.map((level) => ({
        level: parseInt(level.level),
        tokens_required: parseFloat(level.tokens_required),
        token_network: level.token_network,
        price_amount: level.price_amount
          ? parseFloat(level.price_amount)
          : null,
        price_network: level.price_network || null,
        spin_time: parseFloat(level.spin_time),
        total_questions: parseFloat(level.total_questions),
        topics: level.topics,
        prices: level.prices.map((price) => ({
          network: price.network, // Will be either the name or initial network
          amount: parseFloat(price.amount),
          type: price.type,
          id: price.id || null, // Use ID for fetched rewards
        })),
        questions: level?.questions?.map((question) => ({
          question: question.question,
          options: question.options.filter((option) => option.trim() !== ""),
          answer: question.answer,
          answer_time: parseFloat(question.answer_time),
        })),
      })),
    };

    try {
      await dispatch(addgame(newGame));
      setLoading(false);
      onClose();
      onGameAdded();
    } catch (error) {
      console.error("Error adding new game:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleNextLevel = () => {
    setCurrentLevelIndex((prevIndex) =>
      Math.min(prevIndex + 1, gameLevels.length - 1)
    );
  };

  const handlePreviousLevel = () => {
    setCurrentLevelIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const handleSwitchChange = (event) => {
    setIncludeai(event.target.checked);
  };

  return (
    <>
      <Backdrop open={loading} className={classes.backdrop}>
        <CircularProgress color="secondary" />
      </Backdrop>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <TextField
            label="Game Name"
            variant="filled"
            fullWidth
            value={gameName}
            onChange={(e) => setGameName(e.target.value)}
            InputLabelProps={{
              shrink: true,
              style: { paddingBottom: "8px" }, // Adjust as needed
            }}
            InputProps={{
              style: { paddingTop: "8px" }, // Adjust as needed
            }}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            select
            label="Game Code"
            variant="filled"
            fullWidth
            value={gameCode}
            onChange={(e) => setGameCode(e.target.value)}
            InputLabelProps={{
              shrink: true,
              style: { paddingBottom: "8px" }, // Adjust as needed
            }}
            InputProps={{
              style: { paddingTop: "8px" }, // Adjust as needed
            }}
          >
            {gameCodes.map((code) => (
              <MenuItem key={code} value={code}>
                {code}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Token Amount"
            variant="filled"
            fullWidth
            value={tokenAmount}
            onChange={(e) => setTokenAmount(e.target.value)}
            InputLabelProps={{
              shrink: true,
              style: { paddingBottom: "8px" }, // Adjust as needed
            }}
            InputProps={{
              style: { paddingTop: "8px" }, // Adjust as needed
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            select
            label="Token Network"
            variant="filled"
            fullWidth
            value={tokenNetwork}
            onChange={(e) => setTokenNetwork(e.target.value)}
            helperText="Select a token network"
            InputLabelProps={{
              shrink: true,
              style: { paddingBottom: "8px" }, // Adjust as needed
            }}
            InputProps={{
              style: { paddingTop: "8px" }, // Adjust as needed
            }}
          >
            {tokenNetworks.map((network) => (
              <MenuItem key={network} value={network}>
                {network}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Total Count"
            variant="filled"
            fullWidth
            value={totalCount}
            onChange={(e) => setTotalCount(e.target.value)}
            InputLabelProps={{
              shrink: true,
              style: { paddingBottom: "8px" }, // Adjust as needed
            }}
            InputProps={{
              style: { paddingTop: "8px" }, // Adjust as needed
            }}
          />
        </Grid>
        {gameCode === "quiz" && (
          <Grid item xs={12} md={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={includeai}
                  onChange={handleSwitchChange}
                  style={{ color: "blue", backgroundColor: "secondary" }}
                />
              }
              label="Include AI Questions"
            />
          </Grid>
        )}
        {gameLevels.map(
          (level, index) =>
            index === currentLevelIndex && (
              <Grid container spacing={2} key={index}>
                <Grid item xs={12}>
                  <h4>Level {level.level}</h4>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Tokens Required"
                    variant="filled"
                    fullWidth
                    value={level.tokens_required}
                    onChange={(e) =>
                      handleLevelChange(
                        index,
                        "tokens_required",
                        e.target.value
                      )
                    }
                    InputLabelProps={{
                      shrink: true,
                      style: { paddingBottom: "8px" }, // Adjust as needed
                    }}
                    InputProps={{
                      style: { paddingTop: "8px" }, // Adjust as needed
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    select
                    label="Token Network"
                    helperText="Select a token network"
                    variant="filled"
                    fullWidth
                    value={level.token_network}
                    onChange={(e) =>
                      handleTokenNetworkChange(index, e.target.value)
                    }
                    InputLabelProps={{
                      shrink: true,
                      style: { paddingBottom: "8px" }, // Adjust as needed
                    }}
                    InputProps={{
                      style: { paddingTop: "8px" }, // Adjust as needed
                    }}
                  >
                    {tokenNetworks.map((network) => (
                      <MenuItem key={network} value={network}>
                        {network}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                {gameCode === "spin_wheel" && (
                  <>
                    {level.prices.map((price, priceIndex) => (
                      <Grid container spacing={2} key={priceIndex}>
                        <Grid item xs={12} sm={4}>
                          <TextField
                            select
                            label="Prize "
                            helperText="Select a prize "
                            variant="filled"
                            fullWidth
                            value={
                              price.id
                                ? reward.find((r) => r._id === price.id)?.name
                                : price.network
                            }
                            onChange={(e) =>
                              handlePriceNetworkChange(
                                index,
                                priceIndex,
                                e.target.value
                              )
                            }
                            InputLabelProps={{
                              shrink: true,
                              style: { paddingBottom: "8px" }, // Adjust as needed
                            }}
                            InputProps={{
                              style: { paddingTop: "8px" }, // Adjust as needed
                            }}
                          >
                            {priceNetworks.map((network) => (
                              <MenuItem key={network} value={network}>
                                {network}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <TextField
                            label="Prize Amount"
                            variant="filled"
                            type="number"
                            fullWidth
                            value={price.amount}
                            onChange={(e) =>
                              handleLevelChange(index, "prices", {
                                field: "amount",
                                value: e.target.value,
                                priceIndex,
                              })
                            }
                            InputLabelProps={{
                              shrink: true,
                              style: { paddingBottom: "8px" }, // Adjust as needed
                            }}
                            InputProps={{
                              inputProps: { min: 0.1, step: 0.01 },
                              style: { paddingTop: "8px" }, // Adjust as needed
                            }}
                          />
                        </Grid>

                        <Grid item xs={12} sm={4} md={12}>
                          <Button
                            onClick={() => handleRemovePrice(index, priceIndex)}
                            style={{ backgroundColor: "red", color: "white" }}
                            size="small"
                          >
                            Remove Prize
                          </Button>
                        </Grid>
                      </Grid>
                    ))}
                    <Grid item xs={12}>
                      <Button
                        disabled={isAddPriceDisabled}
                        onClick={() => handleAddPrice(index)}
                        variant="outlined"
                        color="primary"
                        size="small"
                      >
                        Add Prize
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label="Spin Time"
                        variant="filled"
                        fullWidth
                        value={level.spin_time}
                        onChange={(e) =>
                          handleLevelChange(index, "spin_time", e.target.value)
                        }
                        InputLabelProps={{
                          shrink: true,
                          style: { paddingBottom: "8px" }, // Adjust as needed
                        }}
                        InputProps={{
                          style: { paddingTop: "8px" }, // Adjust as needed
                        }}
                      />
                    </Grid>
                  </>
                )}

                {gameCode === "quiz" && (
                  <Grid container spacing={2} key={index}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Total Questions"
                        variant="filled"
                        fullWidth
                        value={level.total_questions}
                        onChange={(e) => {
                          handleLevelChange(
                            index,
                            "total_questions",
                            e.target.value
                          );
                        }}
                        InputLabelProps={{
                          shrink: true,
                          style: { paddingBottom: "8px" }, // Adjust as needed
                        }}
                        InputProps={{
                          style: { paddingTop: "8px" }, // Adjust as needed
                          inputProps: {
                            min: 1, // Set minimum value
                            type: "number", // Ensure the input is numeric
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        select
                        label="Topics"
                        variant="filled"
                        value={level.topics}
                        onChange={(e) => handleTopicsChange(index, e)}
                        SelectProps={{
                          multiple: true, // Allow multiple selections
                          value: level.topics || [], // Ensure value is always an array
                        }}
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                          shrink: true,
                          style: { paddingBottom: "8px" }, // Adjust as needed
                        }}
                        InputProps={{
                          style: { paddingTop: "8px" }, // Adjust as needed
                        }}
                      >
                        {topic.map((topic) => (
                          <MenuItem key={topic._id} value={topic._id}>
                            {topic.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Prize Amount"
                        variant="filled"
                        fullWidth
                        value={level.price_amount}
                        onChange={(e) =>
                          handleLevelChange(
                            index,
                            "price_amount",
                            e.target.value
                          )
                        }
                        InputLabelProps={{
                          shrink: true,
                          style: { paddingBottom: "8px" }, // Adjust as needed
                        }}
                        InputProps={{
                          style: { paddingTop: "8px" },
                          inputProps: { min: 0.1, step: 0.01 }, // Adjust as needed
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        select
                        label="Prize Network"
                        helperText="Select a prize "
                        variant="filled"
                        fullWidth
                        value={level.price_network}
                        onChange={(e) =>
                          handlePriceNetworkChangeForQuiz(index, e.target.value)
                        }
                        InputLabelProps={{
                          shrink: true,
                          style: { paddingBottom: "8px" }, // Adjust as needed
                        }}
                        InputProps={{
                          style: { paddingTop: "8px" }, // Adjust as needed
                        }}
                      >
                        {INITIAL_NETWORKS.map((network) => (
                          <MenuItem key={network} value={network}>
                            {network}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12}>
                      <h5>Questions</h5>
                    </Grid>
                    {level?.questions?.map(
                      (question, questionIndex, optionIndex) => (
                        <Grid item xs={12} md={12} key={questionIndex}>
                          <Card className={classes.questionCard}>
                            <CardContent className={classes.cardContent}>
                              <TextField
                                label={`Question ${questionIndex + 1}`}
                                variant="filled"
                                fullWidth
                                value={question.question}
                                onChange={(e) =>
                                  handleLevelChange(
                                    index,
                                    `question_${questionIndex}_question`,
                                    e.target.value
                                  )
                                }
                                InputLabelProps={{
                                  shrink: true,
                                  style: { paddingBottom: "8px" }, // Adjust as needed
                                }}
                                InputProps={{
                                  style: { paddingTop: "8px" }, // Adjust as needed
                                }}
                              />
                              <Grid container spacing={2}>
                                {question?.options?.map(
                                  (option, optionIndex) => (
                                    <Grid item xs={12} sm={6} key={optionIndex}>
                                      <TextField
                                        label={`Option ${optionIndex + 1}`}
                                        variant="filled"
                                        fullWidth
                                        value={option}
                                        onChange={(e) =>
                                          handleLevelChange(index, `options`, {
                                            optionIndex,
                                            newValue: e.target.value,
                                          })
                                        }
                                        InputLabelProps={{
                                          shrink: true,
                                          style: { paddingBottom: "8px" }, // Adjust as needed
                                        }}
                                        InputProps={{
                                          style: { paddingTop: "8px" }, // Adjust as needed
                                        }}
                                      />
                                    </Grid>
                                  )
                                )}
                              </Grid>

                              <Grid item xs={12}>
                                <Button
                                  onClick={() =>
                                    handleAddOption(
                                      index,
                                      questionIndex,
                                      optionIndex
                                    )
                                  }
                                  disabled={!isAddOptionEnabled(questionIndex)}
                                  color="primary"
                                  variant="contained"
                                  size="small"
                                  style={{ marginRight: "10px" }}
                                >
                                  Add Option
                                </Button>

                                <Button
                                  onClick={() =>
                                    handleRemoveOption(
                                      index,
                                      questionIndex,
                                      optionIndex
                                    )
                                  }
                                  style={{
                                    backgroundColor: "red",
                                    color: "white",

                                    opacity:
                                      question.options.length < 1 ? 0.5 : 1,
                                    cursor:
                                      question.options.length < 1
                                        ? "not-allowed"
                                        : "pointer",
                                  }}
                                  size="small"
                                  disabled={question.options.length < 1}
                                >
                                  Remove Option
                                </Button>
                              </Grid>
                              <TextField
                                label={`Answer`}
                                variant="filled"
                                fullWidth
                                value={question.answer}
                                onChange={(e) =>
                                  handleLevelChange(
                                    index,
                                    `question_${questionIndex}_answer`,
                                    e.target.value
                                  )
                                }
                                InputLabelProps={{
                                  shrink: true,
                                  style: { paddingBottom: "8px" }, // Adjust as needed
                                }}
                                InputProps={{
                                  style: { paddingTop: "8px" }, // Adjust as needed
                                }}
                              />
                              <TextField
                                label={`Answer Time `}
                                variant="filled"
                                fullWidth
                                value={question.answer_time}
                                onChange={(e) =>
                                  handleLevelChange(
                                    index,
                                    `question_${questionIndex}_answer_time`,
                                    e.target.value
                                  )
                                }
                                InputLabelProps={{
                                  shrink: true,
                                  style: { paddingBottom: "8px" }, // Adjust as needed
                                }}
                                InputProps={{
                                  style: { paddingTop: "8px" }, // Adjust as needed
                                }}
                              />
                            </CardContent>
                            <CardActions>
                              <Button
                                onClick={() =>
                                  handleRemoveQuestion(index, questionIndex)
                                }
                                style={{
                                  backgroundColor: "red",
                                  color: "white",
                                }}
                                size="small"
                              >
                                Remove Question
                              </Button>
                            </CardActions>
                          </Card>
                        </Grid>
                      )
                    )}
                    <Grid item xs={12} style={{ marginTop: "20px" }}>
                      <CustomTextButton
                        color="primary"
                        onClick={() => handleAddQuestion(index)}
                        variant="contained"
                        size="small"
                        title="Add Question"
                      />
                    </Grid>
                  </Grid>
                )}
              </Grid>
            )
        )}
        <Grid item xs={12} style={{ marginTop: "20px" }}>
          <Button
            onClick={handleAddLevel}
            variant="outlined"
            color="primary"
            size="small"
            disabled={isAddLevelDisabled}
          >
            Add Level
          </Button>
        </Grid>
        <Grid container spacing={2} justify="space-between">
          <Grid item>
            <Button
              onClick={handlePreviousLevel}
              variant="outlined"
              color="primary"
              disabled={currentLevelIndex === 0}
              size="small"
            >
              Previous
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={handleNextLevel}
              variant="outlined"
              color="primary"
              disabled={currentLevelIndex === gameLevels.length - 1}
              size="small"
            >
              Next
            </Button>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Button
            size="small"
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            disabled={isButtonDisabled || loading}
          >
            Add Game
          </Button>
          <Button
            size="small"
            variant="contained"
            color="default"
            style={{ marginLeft: "16px" }}
            onClick={onClose}
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default AddGameModal;
