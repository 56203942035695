import {
  TOGGLE_LOADING,
  CREATE_CAMPAIGN_SUCCESS,
  ACTIVATE_CAMPAIGN_SUCCESS,
  FETCH_CAMPAIGN_SUCCESS,
  FETCH_CAMPAIGN_DETAILS_SUCCESS,
  UPDATE_CAMPAIGN_SUCCESS,
  LIST_CAMPAIGN_GAMES_SUCCESS,
} from "../types";

const initialState = {
  data: [],
  campaignDetails: null,
  campaignGames: [],
  hasMore: false,
  loading: false,
  error: null,
  message: null,
};

const campaignsReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    case FETCH_CAMPAIGN_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        hasMore: action.payload.hasMore,
        loading: false,
        error: null,
      };

    case CREATE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        message: action.payload,
        loading: false,
      };

      case UPDATE_CAMPAIGN_SUCCESS:
        return {
          ...state,
          data: state.data.map((campaign) =>
            campaign._id === action.payload._id ? { ...campaign, ...action.payload } : campaign
          ),
          message: action.payload.message, 
          loading: false,
        };
  

    case ACTIVATE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        data: state.data.map((campaign) =>
          campaign.id === action.payload.id
            ? { ...campaign, is_active: true }
            : campaign
        ),
      };
      case FETCH_CAMPAIGN_DETAILS_SUCCESS:
        return {
          ...state,
          campaignDetails: action.payload,  // Update the campaign details in the state
        };
        case LIST_CAMPAIGN_GAMES_SUCCESS:
      return {
        ...state,
        campaignGames: action.payload,
      };

    default:
      return state;
  }
};

export default campaignsReducer;
