export const LIST_ALL_ANALYTICS = `
  query ListAllAnalytics($analytics: GetAllAnalyticsDto!) {
    list_all_analytics(analytics: $analytics) {
      data {
        _id
        ip_address
        location
        browser
        user_id
        campaign_id
        game_id
        timestamp
        admin_code
        date
        device_type
        device_vendor
        device_model
        type
      }
    }
  }
`;
