import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Box, Grid, Card } from "@material-ui/core";
import CustomHeader from "components/Headers/CustomHeader";
import componentStyles from "assets/theme/views/admin/tables.js";
import CustomTable from "components/CustomTable/CustomTable";
import CustomIconButton from "components/CustomButton/CustomIconButton";

import {
  fetchAllgames,
  resetgameData,
  toggleGameActiveStatus,
} from "store/actions/gameAction";

import { toggleModal } from "store/actions/modalActions";
import CustomModal from "components/CustomModal/CustomModal";
import ModalContent from "./ModalContent";
import {
  Button,
  Switch,
} from "../../../../node_modules/@material-ui/core/index";

import { useHistory } from "react-router-dom";
import CustomTextButton from "components/CustomButton/CustomTextButton";
import { Add } from "../../../../node_modules/@material-ui/icons/index";

const useStyles = makeStyles(componentStyles);

const Games = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [pageNumber, setPageNumber] = useState(1);
  const pageSize = 10;

  const {
    gamelist: userData,
    loading,
    error,
    hasMore,
  } = useSelector(({ games }) => ({
    gamelist: games.gamelist,
    loading: games.loading,
    error: games.error,
    hasMore: games.hasMore,
  }));

  useEffect(() => {
    dispatch(resetgameData());
  }, []);

  useEffect(() => {
    dispatch(fetchAllgames(pageNumber, pageSize));
  }, [dispatch, pageNumber]);

  const handleCloseModal = () => {
    dispatch(toggleModal(false));
  };

  const handleRowClick = (_id) => {
    history.push(`/app/game/${_id}`);
  };

  const handleToggleActiveStatus = (gameId, isActive) => {
    dispatch(toggleGameActiveStatus(gameId, isActive));
  };

  const isSameGameCodeActive = (gameCode) => {
    return userData.some(
      (game) => game.game_code === gameCode && game.is_active
    );
  };

  const loadMore = () => {
    if (hasMore) {
      setPageNumber((prevPage) => {
        const nextPage = prevPage + 1;
        dispatch(fetchAllgames(nextPage, pageSize)); // Fetch next page
        return nextPage; // Return updated page number
      });
    }
  };

  const handleGameAdded = () => {
    dispatch(fetchAllgames()); // Refresh the game list
  };

  return (
    <>
      <CustomHeader />
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
        <Grid container justify="flex-start" spacing={3}>
          <Grid item style={{ width: "100%" }}>
            <Card style={{ backgroundColor: "grey" }}>
              <Grid container spacing={3}>
                <Grid item style={{ padding: "1.5em" }}>
                  <CustomIconButton
                    title="Add Game"
                    icon={<Add />}
                    onClick={() => {
                      dispatch(toggleModal(true));
                    }}
                    style={{ width: "100%" }}
                  />
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
        <br />
        <CustomTable
          title="Games"
          columns={[
            {
              title: "Actions",
              field: "actions",
              render: (rowData) => (
                <CustomTextButton
                  color="primary"
                  variant="outlined"
                  onClick={() => handleRowClick(rowData._id)}
                  size="small"
                  title="Open"
                />
              ),
            },
            { title: "Game Name", field: "name" },
            { title: "Game Code", field: "game_code" },
            {
              title: "Active Status",
              field: "is_active",
              render: (rowData) => (
                <Switch
                  checked={rowData.is_active}
                  onChange={() =>
                    handleToggleActiveStatus(rowData._id, !rowData.is_active)
                  }
                  color="primary"
                  disabled={
                    isSameGameCodeActive(rowData.game_code) &&
                    !rowData.is_active
                  }
                  inputProps={{ "aria-label": "Toggle Active Status" }}
                />
              ),
            },
            { title: "Total Levels", field: "total_levels" },
          ]}
          data={userData}
          loading={loading}
          error={error}
        />

        <Button
          variant="contained"
          color="primary"
          onClick={loadMore}
          style={{ padding: "10px", marginTop: "20px" }}
          disabled={!hasMore}
        >
          Load More
        </Button>
      </Container>

      <CustomModal
        onClose={handleCloseModal}
        content={
          <ModalContent
            onClose={handleCloseModal}
            onGameAdded={handleGameAdded}
          />
        }
      />
    </>
  );
};
export default Games;
