import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Box,
  Grid,
  Card,
  Typography,
  Button,
  DialogActions,
  DialogContent,
  Dialog,
  DialogTitle,
} from "@material-ui/core";
import CustomHeader from "components/Headers/CustomHeader";
import componentStyles from "assets/theme/views/admin/tables.js";
import CustomIconButton from "components/CustomButton/CustomIconButton";
import CustomSidebar from "components/CustomSidebar/CustomSidebar";
import { toggleSidebar } from "store/actions";
import {
  deleteQuizQuestion,
  fetchQuizQuestions,
  generateQuizQuestions,
  resetQuizData,
} from "store/actions/quizquestionAction";

import { generateInputs, initialValues, validationSchema } from "./send_topic";
import { Plus } from "../../../../node_modules/react-feather/dist/index";
import CustomDialogBox from "components/CustomDialogBox/CustomDialogBox";
import UpdateQuestionModal from "./updatequizmodal";
import CSVUpload from "./csv";

const useStyles = makeStyles(componentStyles);

const Quiz_Ques = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [sidebarInputs, setSidebarInputs] = useState([]);
  const [refreshPage, setRefreshPage] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [questionToDelete, setQuestionToDelete] = useState(null);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [showUploadOptions, setShowUploadOptions] = useState(false);

  const [pageNumber, setPageNumber] = useState(1);
  const pageSize = 20;

  const { quiz, loading, error, hasMore } = useSelector((state) => ({
    quiz: state.quiz.quiz,
    loading: state.quiz.loading,
    error: state.quiz.error,
    hasMore: state.quiz.hasMore,
  }));

  useEffect(() => {
    dispatch(resetQuizData());
  }, []);

  useEffect(() => {
    dispatch(fetchQuizQuestions(pageNumber, pageSize));
  }, [dispatch, refreshPage]);

  const handleSendTopic = () => {
    setSidebarInputs(generateInputs());
    dispatch(toggleSidebar(true));
  };

  const handleSubmit = (values) => {
    const { topic } = values;

    dispatch(generateQuizQuestions({ topic }))
      .then(() => {
        setRefreshPage((prev) => !prev);
        dispatch(toggleSidebar(false));
        setLoadingSubmit(false);
      })
      .catch((error) => {
        console.error("Error sending topic:", error);
        setLoadingSubmit(false);
      });
  };

  const handleSidebarClose = () => {
    dispatch(toggleSidebar(false));
  };

  const loadMore = () => {
    if (hasMore) {
      setPageNumber((prevPage) => prevPage + 1);
    }
    dispatch(fetchQuizQuestions(pageNumber, pageSize));
  };

  const handleDelete = (id) => {
    if (!id) {
      console.error("ID is required for deletion.");
      return;
    }

    setQuestionToDelete(id);
    setShowDialog(true); // Open the dialog box
  };

  const handleDialogBoxSubmit = () => {
    if (questionToDelete) {
      dispatch(deleteQuizQuestion(questionToDelete))
        .then(() => {
          // Refresh the quiz questions after successful deletion
          setRefreshPage((prev) => !prev);
        })
        .catch((error) => {
          console.error("Error deleting question:", error);
        });
    }
    setShowDialog(false); // Close the dialog box
    setQuestionToDelete(null); // Clear the question ID
  };

  const handleDialogBoxCancel = () => {
    setShowDialog(false); // Close the dialog box
    setQuestionToDelete(null); // Clear the question ID
  };

  const handleOpenModal = (question) => {
    setSelectedQuestion(question);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedQuestion(null);
    setModalOpen(false);
    setRefreshPage((prev) => !prev);
    dispatch(resetQuizData());
  };

  const handleCSVUpload = (data) => {

    if (data.length > 1000) {
      alert("The maximum number of questions allowed is 1000. Please upload a smaller file.");
      return;
    }

    dispatch(
      generateQuizQuestions({
        topic: "CSV Uploaded",
        source: "csv",
        questions_data: data,
      })
    )
      .then(() => {
        setRefreshPage((prev) => !prev);
        setShowUploadOptions(false);
      })
      .catch((error) => {
        console.error("Error generating questions from CSV:", error);
      });
  };

  const handleOpenFromOpenAI = () => {
    setSidebarInputs(generateInputs());
    dispatch(toggleSidebar(true));
    setShowUploadOptions(false); // Close the upload options menu
  };

  const handleCloseUploadOptions = () => {
    setShowUploadOptions(false); // Close the upload options menu
  };

  return (
    <>
      <CustomHeader />
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
        <Grid container justify="flex-start" spacing={3}>
          <Grid item style={{ width: "100%" }}>
            <Card style={{ backgroundColor: "grey" }}>
              <Grid container spacing={3}>
                <Grid item style={{ padding: "1.5em" }}>
                  <CustomIconButton
                    title="Create Questions"
                    icon={<Plus />}
                    onClick={() => setShowUploadOptions(true)}
                    style={{ width: "100%" }}
                  />
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
        <br />
        <Grid container spacing={3}>
          {quiz?.map((question, index) => (
            <Grid item xs={12} md={12} key={index}>
              <Card style={{ padding: "1em" }}>
                <Typography variant="h4">{question.question}</Typography>
                <Typography variant="body1">
                  Options: {question.options.join(", ")}
                </Typography>
                <Typography variant="body2">
                  Answer: {question.answer}
                </Typography>
                <Typography variant="body2">
                  Topic:{" "}
                  {question.topic_id ? `${question.topic_id.name} ` : "N/A"}
                </Typography>
                <Grid item xs={6} md={6}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleOpenModal(question)}
                    style={{ marginTop: "10px", marginRight: "20px" }}
                    size="small"
                  >
                    Update
                  </Button>
                  <Button
                    variant="contained"
                    style={{
                      color: "white",
                      backgroundColor: "red",
                      marginTop: "10px",
                      borderColor: "red",
                    }}
                    onClick={() => handleDelete(question._id)} // Use _id instead of id
                    size="small"
                  >
                    Delete
                  </Button>
                </Grid>
              </Card>
            </Grid>
          ))}
        </Grid>
        {loading && <Typography>Loading...</Typography>}
        {error && <Typography color="error">Error: {error}</Typography>}

        <Button
          variant="contained"
          color="primary"
          onClick={loadMore}
          disabled={!hasMore || loading}
          style={{ padding: "10px", marginTop: "20px" }}
        >
          Load More
        </Button>
      </Container>

      <Dialog open={showUploadOptions} onClose={handleCloseUploadOptions}>
        <DialogTitle>
          <Typography variant="h4" style={{ fontSize: "2em" }}>
            Choose Method
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenFromOpenAI}
            style={{ marginBottom: "20px" }}
            size="small"
          >
            Create Using AI
          </Button>
          <CSVUpload onUpload={handleCSVUpload} />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseUploadOptions}
            color="primary"
            size="small"
            variant="outlined"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <CustomSidebar
        title="Create Questions"
        inputs={sidebarInputs}
        validationSchema={validationSchema}
        initialValues={initialValues}
        handleSubmit={handleSubmit}
        handleClose={handleSidebarClose}
      />
      <CustomDialogBox
        open={showDialog}
        handleClose={handleDialogBoxCancel}
        title="Are you sure you want to continue?"
        handleSubmitButton={handleDialogBoxSubmit}
        handleCancelButton={handleDialogBoxCancel}
      />
      {modalOpen && (
        <UpdateQuestionModal
          open={modalOpen}
          onClose={handleCloseModal}
          question={selectedQuestion}
        />
      )}
    </>
  );
};

export default Quiz_Ques;
