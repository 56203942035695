import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchGameDetails } from "store/actions/gameAction";
import { Box, Typography, Card, CardContent, Grid } from "@material-ui/core";
import CustomHeader from "components/Headers/CustomHeader";
import { Button, Modal, Backdrop } from "@material-ui/core";
import UpdateGameModal from "./updatemodalcontent";
import { history } from "utils/history";
import { IconButton } from "../../../../node_modules/@material-ui/core/index";
import { ArrowBackTwoTone } from "../../../../node_modules/@material-ui/icons/index";
import { toggleModal } from "store/actions/modalActions";

const GamesDetails = () => {
  const { _id } = useParams();
  const dispatch = useDispatch();
  const reward = useSelector((state) => state.reward.data);
  const { gameDetails, loading, error } = useSelector((state) => state.games);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (_id) {
      dispatch(fetchGameDetails(_id));
    }
  }, [_id, dispatch]);

  const handleUpdateClick = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    dispatch(toggleModal(false));
  };
  const handleBackClick = () => {
    history.goBack();
  };
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  // Function to filter game details for modal content
  const filterGameDetails = (gameDetails) => {
    if (!gameDetails) return null;

    const filteredGameDetails = {
      _id: gameDetails._id,
      name: gameDetails.name,
      game_code: gameDetails.game_code,
      include_ai_questions: gameDetails.include_ai_questions,
      token_amount: gameDetails.token_amount,
      token_network: gameDetails.token_network,
      total_count: gameDetails.total_count,
      game_url:gameDetails.game_url,
      campaign: gameDetails.campaign,
      levels: gameDetails.levels.map((level) => ({
        level: level.level,
        tokens_required: level.tokens_required,
        token_network: level.token_network,
        price_amount: level.price_amount,
        price_network: level.price_network,
        total_questions: level.total_questions,
        topics:
          level.topics?.data.map((topic) => ({
            _id: topic._id,
            name: topic.name,
          })) || [],
        spin_time: level.spin_time,
        question: level.question,
        options: level.options,
        answer: level.answer,
        answer_time: level.answer_time,
        prices: level.price_details.prices.map((price) => ({
          network: price.network,
          amount: price.amount,
          type: price.type,
          id: price.id,
        })),
        // Include questions if present
        questions: level.questions?.data.map((question) => ({
          question: question.question,
          options: question.options, // Assuming options is already an array
          answer: question.answer,
          answer_time: question.answer_time,
        })),
      })),
    };

    return filteredGameDetails;
  };

  if (gameDetails?.game_code === "quiz") {
    console.log("Include AI Questions:", gameDetails?.include_ai_questions);
  }

  return (
    <div>
      {gameDetails ? (
        <>
          <CustomHeader />
          <Box sx={{ padding: "30px" }}>
            <Typography variant="h4">Name :{gameDetails?.name}</Typography>
            <Typography variant="subtitle1">
              Game Code: {gameDetails?.game_code}
            </Typography>
            {gameDetails?.game_url && (
            <Typography variant="subtitle1">
              Game URL: {gameDetails?.game_url}
            </Typography>
            )}
            {gameDetails?.campaign?.name && (
              <Typography variant="body2">
                Campaign Name: {gameDetails?.campaign.name}
              </Typography>
            )}
            {gameDetails?.game_code === "quiz" ? (
              <Typography variant="body2">
                AI Question : {gameDetails?.include_ai_questions ? "Yes" : "No"}
              </Typography>
            ) : (
              false
            )}
            <Typography variant="h5">Levels</Typography>
            <Grid container direction="column" spacing={3}>
              {gameDetails?.levels.map((level) => (
                <Grid item xs={12} sm={6} md={12} key={level?._id}>
                  <Card style={{ backgroundColor: "#f0f0f0" }}>
                    <CardContent>
                      <Typography variant="h4">Level {level?.level}</Typography>
                      <Typography variant="body2">
                        Tokens Required: {level?.tokens_required}
                      </Typography>
                      <Typography variant="body2">
                        Token Network: {level?.token_network}
                      </Typography>
                      {gameDetails?.game_code === "quiz" ? (
                        <Typography variant="body2">
                          Prize Amount: {level?.price_amount}
                        </Typography>
                      ) : null}
                      {gameDetails?.game_code === "quiz" ? (
                        <Typography variant="body2">
                          Prize Network: {level?.price_network}
                        </Typography>
                      ) : null}
                      {gameDetails?.game_code === "quiz" ? (
                        <Typography variant="body2">
                          Total Questions: {level.total_questions}
                        </Typography>
                      ) : null}
                      {gameDetails?.game_code === "quiz" &&
                      level?.topics?.data?.length > 0 ? (
                        <Typography variant="body2">
                          Topics:{" "}
                          {level.topics.data
                            .map((topic) => topic.name)
                            .join(", ")}
                        </Typography>
                      ) : null}

                      <Typography variant="body2">
                        Active: {level?.is_active ? "Yes" : "No"}
                      </Typography>
                      {gameDetails?.game_code === "spin_wheel" ? (
                        <Typography variant="body2">
                          Spin Time: {level?.spin_time}
                        </Typography>
                      ) : null}

                      {gameDetails?.game_code === "spin_wheel" ? (
                        <Typography variant="h3">Prizes</Typography>
                      ) : null}
                      {gameDetails?.game_code === "spin_wheel" ? (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                          }}
                        >
                          {level?.price_details.prices.map((price, index) => (
                            <Card key={index} style={{ margin: "8px" }}>
                              <CardContent>
                                <Typography variant="h5">
                                  Prize {index + 1}
                                </Typography>
                                <Typography variant="body2">
                                  Prize:
                                  {price?.network === "funq" ||
                                  price?.network === "solana"
                                    ? price?.network
                                    : price?.network === null
                                    ? price?.name
                                    : price?.name}
                                </Typography>
                                <Typography variant="body2">
                                  Amount: {price?.amount}
                                </Typography>
                                <Typography variant="body2">
                                  Type: {price?.type}
                                </Typography>
                              </CardContent>
                            </Card>
                          ))}
                        </div>
                      ) : null}
                      {gameDetails?.game_code === "quiz" ? (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginTop: "10px",
                          }}
                        >
                          {level?.questions?.data?.map((question, index) => (
                            <Card key={index} style={{ marginBottom: "10px" }}>
                              <CardContent>
                                <Typography variant="body2">
                                  Question: {question?.question}
                                </Typography>
                                <Typography variant="body2">
                                  Options:
                                </Typography>
                                {question?.options.map((option, idx) => (
                                  <Typography key={idx} variant="body2">
                                    - {option}
                                  </Typography>
                                ))}
                                <Typography variant="body2">
                                  Answer: {question?.answer}
                                </Typography>
                                <Typography variant="body2">
                                  Answer Time: {question?.answer_time}
                                </Typography>
                              </CardContent>
                            </Card>
                          ))}
                        </div>
                      ) : null}
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleUpdateClick}
              size="small"
              style={{ position: "absolute", top: "200px", right: "30px" }}
            >
              Update Game
            </Button>
            <IconButton
              variant="contained"
              onClick={handleBackClick}
              style={{
                position: "absolute",
                top: "200px",
                left: "30px",
                backgroundColor: "white",
                color: "black",
              }}
            >
              <ArrowBackTwoTone />
            </IconButton>
          </Box>

          <Modal
            open={isModalOpen}
            onClose={handleModalClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Box>
              <UpdateGameModal
                gameData={filterGameDetails(gameDetails)}
                onClose={handleModalClose}
              />
            </Box>
          </Modal>
        </>
      ) : (
        <p>No game details found</p>
      )}
    </div>
  );
};

export default GamesDetails;
