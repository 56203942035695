import { combineReducers } from "redux";
import loadingReducer from "./loadingReducer";
import authReducer from "./authReducer";
import { LOGOUT } from "../types";
import sidebarReducer from "./sidebarReducer";
import modalReducer from "./modalReducer";
import walletsReducer from "./walletReducer";
import usersReducer from "./userReducer";
import gamesReducer from "./gameReducer";
import quizReducer from "./quizreducer";
import topicReducer from "./topicReducer";
import rewardsReducer from "./rewardreducer";
import campaignsReducer from "./campaignreducer";
import analyticsReducer from "./analyticreducer";

const appReducer = combineReducers({
  auth: authReducer,
  loading: loadingReducer,
  sidebar: sidebarReducer,
  modal: modalReducer,
  wallets: walletsReducer,
  users: usersReducer,
  games: gamesReducer,
  ledger: walletsReducer,
  quiz: quizReducer,
  topic: topicReducer,
  reward:rewardsReducer,
  campaign:campaignsReducer,
  analytics:analyticsReducer,
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT) {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
