
import { TOGGLE_LOADING, FETCH_USERS_SUCCESS, FETCH_LEDGER_SUCCESS, } from "../types";

const initialState = {
  list: [],
  loading: false,
  error: null,
  list1:[]
};

const walletsReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_LOADING:
      return { ...state, loading: action.payload };
    case FETCH_USERS_SUCCESS:
      return {
        ...state,
        list: action.payload,
        error: null
      };
      case FETCH_LEDGER_SUCCESS:
      return {
        ...state,
        list1: action.payload,
        error: null
      };
   
    default:
      return state;
  }
};

export default walletsReducer;


// import { LIST_ALL_WALLETS, RESET_WALLET_LIST_DATA } from "../types";

// const INITIAL_STATE = {
//     list: [],
//     showViewMore: false,
//     pageNumber: 1,
//     pageSize: 10,
//     details: {},
// };

// const walletsReducer = (state = INITIAL_STATE, { type, payload }) => {
//     switch (type) {
//         case LIST_ALL_WALLETS:
//             return {
//                 ...state,
//                 pageSize: 10,
//                 ...payload,
//             };

//         case RESET_WALLET_LIST_DATA:
//             return INITIAL_STATE;
//         default:
//             return state;
//     }
// };

// export default walletsReducer;
