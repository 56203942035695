import { TOGGLE_LOADING, CREATE_CAMPAIGN_SUCCESS, FETCH_CAMPAIGN_SUCCESS, ACTIVATE_CAMPAIGN_SUCCESS, FETCH_CAMPAIGN_DETAILS_SUCCESS, UPDATE_CAMPAIGN_SUCCESS, LIST_CAMPAIGN_GAMES_SUCCESS } from "../types";
import { post } from "services";
import { ACTIVATE_CAMPAIGN, CREATE_NEW_CAMPAIGN, GET_CAMPAIGN_DETAILS, LIST_ALL_CAMPAIGNS, LIST_ALL_CAMPAIGN_GAMES, UPDATE_CAMPAIGN } from "graphql/campaign";


export const createNewCampaign = (campaignData) => {
  return async (dispatch) => {
    dispatch({ type: TOGGLE_LOADING, payload: true });

    try {
      const apiResponse = await post("", {
        query: CREATE_NEW_CAMPAIGN,
        variables: { campaign: campaignData },
      });

      if (apiResponse.data && apiResponse.data.data.add_new_campaign) {
        const { message } = apiResponse.data.data.add_new_campaign;

        dispatch({
          type: CREATE_CAMPAIGN_SUCCESS,
          payload: message,
        });
      }
    } catch (error) {
      console.error("Error creating campaign:", error);
    } finally {
      dispatch({ type: TOGGLE_LOADING, payload: false });
    }
  };
};

export const fetchAllCampaigns = (fetchVariables) => {
  return async (dispatch) => {
    dispatch({ type: TOGGLE_LOADING, payload: true });

    try {
      const apiResponse = await post("", {
        query: LIST_ALL_CAMPAIGNS,
        variables: { campaign: fetchVariables },
      });

      if (apiResponse.data && apiResponse.data.data.list_all_campaigns) {
        const { data, hasMore } = apiResponse.data.data.list_all_campaigns;

        dispatch({
          type: FETCH_CAMPAIGN_SUCCESS,
          payload: { data, hasMore },
        });
      }
    } catch (error) {
      console.error("Error fetching campaigns:", error);
    } finally {
      dispatch({ type: TOGGLE_LOADING, payload: false });
    }
  };
};


export const activateCampaign = (campaignId) => {
  return async (dispatch) => {
    dispatch({ type: TOGGLE_LOADING, payload: true });

    try {
      const apiResponse = await post("", {
        query: ACTIVATE_CAMPAIGN,
        variables: { campaign: { _id: campaignId } },
      });

      if (apiResponse.data && apiResponse.data.activate_campaign) {
        const { message } = apiResponse.data.activate_campaign;

        dispatch({
          type: ACTIVATE_CAMPAIGN_SUCCESS,
          payload: message,
        });
      }
    } catch (error) {
     
      console.error("Error activating campaign:", error);
    } finally {
      dispatch({ type: TOGGLE_LOADING, payload: false });
    }
  };
};

export const fetchCampaignDetails = (campaignId) => {
  return async (dispatch) => {
    dispatch({ type: TOGGLE_LOADING, payload: true });

    try {
      const apiResponse = await post("", {
        query: GET_CAMPAIGN_DETAILS,
        variables: { campaign: { _id: campaignId } },
      });

      if (apiResponse.data && apiResponse.data.data.campaign_details) {
        const { data } = apiResponse.data.data.campaign_details;

        dispatch({
          type: FETCH_CAMPAIGN_DETAILS_SUCCESS,
          payload: data,
        });
      }
    } catch (error) {
      console.error("Error fetching campaign details:", error);
    } finally {
      dispatch({ type: TOGGLE_LOADING, payload: false });
    }
  };
};

export const updateCampaign = (campaignData) => {
  return async (dispatch) => {
    dispatch({ type: TOGGLE_LOADING, payload: true });

    try {
      const apiResponse = await post("", {
        query: UPDATE_CAMPAIGN,
        variables: { campaign: campaignData },
      });

      if (apiResponse.data && apiResponse.data.data.update_campaign) {
        const { message } = apiResponse.data.data.update_campaign;

        dispatch({
          type: UPDATE_CAMPAIGN_SUCCESS,
          payload: message,
        });
      }
    } catch (error) {
      console.error("Error updating campaign:", error);
    } finally {
      dispatch({ type: TOGGLE_LOADING, payload: false });
    }
  };
};

export const listAllCampaignGames = ({ campaign_id, page_number, page_size }) => {
  return async (dispatch) => {
    dispatch({ type: TOGGLE_LOADING, payload: true });

    try {
      const apiResponse = await post("", {
        query: LIST_ALL_CAMPAIGN_GAMES,
        variables: { campaign: { campaign_id, page_number, page_size } },
      });

      if (apiResponse.data && apiResponse.data.data.list_all_campaign_games) {
        const { data } = apiResponse.data.data.list_all_campaign_games;

        dispatch({
          type: LIST_CAMPAIGN_GAMES_SUCCESS,
          payload: data,
        });
      }
    } catch (error) {
      console.error("Error listing campaign games:", error);
    } finally {
      dispatch({ type: TOGGLE_LOADING, payload: false });
    }
  };
};

