import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Box } from "@material-ui/core";

import CustomHeader from "components/Headers/CustomHeader";
import CustomTable from "components/CustomTable/CustomTable";

import componentStyles from "assets/theme/components/navbar-dropdown";
import CustomIconButton from "components/CustomButton/CustomIconButton";
import {
  Button,
  Card,
  Grid,
} from "../../../../node_modules/@material-ui/core/index";
import { useState } from "react";
import { toggleSidebar } from "store/actions/sidebarActions";
import {
  generateInputs,
  initialValues,
  validationSchema,
} from "./create_campaign";
import CustomSidebar from "components/CustomSidebar/CustomSidebar";
import { PlusCircle } from "../../../../node_modules/react-feather/dist/index";

import {
  createNewCampaign,
  fetchAllCampaigns,
} from "store/actions/campaignAction";
import { useHistory } from "../../../../node_modules/react-router-dom/cjs/react-router-dom";

const useStyles = makeStyles(componentStyles);

function Campaigns() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [sidebarInputs, setSidebarInputs] = useState([]);
  const [refreshPage, setRefreshPage] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const history = useHistory();

  const {
    campaign: campaignData,
    loading,
    error,
  } = useSelector(({ campaign }) => ({
    campaign: campaign.data,
    loading: campaign.loading,
    error: campaign.error,
  }));

  useEffect(() => {
    const fetchVariables = {
      page_number: 1,
      page_size: 20,
    };
    dispatch(fetchAllCampaigns(fetchVariables));
  }, [dispatch, refreshPage]);

  const handleCreateCampaign = () => {
    setSidebarInputs(generateInputs());
    dispatch(toggleSidebar(true));
  };

  const formatDate = (date) => {
    // Convert date to YYYY-MM-DD format
    return date.toISOString().split("T")[0];
  };
  const handleSubmit = (values) => {
    console.log("Submitting values:", values);
    const { name, description, start_date, end_date } = values;

    // Prepare the payload with only the fields that are provided
    const payload = {
      name,
    };

    if (description) {
      payload.description = description;
    }

    if (start_date) {
      payload.start_date = formatDate(new Date(start_date));
    }

    if (end_date) {
      payload.end_date = formatDate(new Date(end_date));
    }

    dispatch(createNewCampaign(payload))
      .then(() => {
        setRefreshPage((prev) => !prev); // Refresh the page or update state
        dispatch(toggleSidebar(false)); // Close the sidebar
        setLoadingSubmit(false); // Stop loading spinner
      })
      .catch((error) => {
        console.error("Error creating campaign:", error);
        setLoadingSubmit(false); // Stop loading spinner
      });
  };

  const handleSidebarClose = () => {
    dispatch(toggleSidebar(false)); // Close the sidebar
  };

  const handleRowClick = (_id) => {
    history.push(`/app/campaign/${_id}`); // Redirect to campaign details page
  };

  return (
    <>
      <CustomHeader />
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
        <Grid container justify="flex-start" spacing={3}>
          <Grid item style={{ width: "100%" }}>
            <Card style={{ backgroundColor: "grey" }}>
              <Grid container spacing={3}>
                <Grid item style={{ padding: "1.5em" }}>
                  <CustomIconButton
                    title="Create Campaign"
                    icon={<PlusCircle />}
                    onClick={handleCreateCampaign}
                    style={{ width: "100%" }}
                  />
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
        <br />
        {loading && <p>Loading...</p>}
        {error && <p>Error: {error}</p>}
        <CustomTable
          title="Campaigns"
          columns={[
            {
              title: "Actions",
              field: "actions",
              render: (rowData) => (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => handleRowClick(rowData._id)}
                  size="small"
                >
                  Open
                </Button>
              ),
            },
            { title: "Name", field: "name" },
            { title: "Start Date", field: "start_date" },
            { title: "End Date", field: "end_date" },
          ]}
          data={campaignData}
        />
      </Container>
      <CustomSidebar
        title="Create Campaign"
        inputs={sidebarInputs}
        validationSchema={validationSchema}
        initialValues={initialValues}
        handleSubmit={handleSubmit}
        handleClose={handleSidebarClose}
      />
    </>
  );
}

export default Campaigns;
